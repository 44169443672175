<div class="container-fluid">
  <div class="row mt-5">
    <div class="col"></div>
    <div class="col-4">
      <div class="card shadow">
        <div class="card-body">
          <form>
            <form>
              <div class="mb-3">
                <h4 class="text-danger text-muted">Welcome</h4>
              </div>
              <div class="mb-3">
                <p class="text-muted">A verification link has been sent to your email address.</p>
              </div>
              <div class="mb-3">
                <p class="text-muted">After verification, you can </p>
                <a routerLink="/training" style="text-decoration: none;">click here to proceed</a>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
