<div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 280px;min-height:100vh; display: block;">
  <a class="align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
    <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg>
    <span class="fs-4">Welcome{{userProfile ? ', ' + userProfile.name : ''}}</span>
  </a>
  <hr>
  <ul class="nav nav-pills flex-column mb-auto">
    <li *ngFor="let selectPage of pages; index as i" class="nav-item">
      <a *ngIf="selectPage == selectedPage" [routerLink]="'/'+selectPage" attr.data-cy="{{selectPage}}" class="nav-link active" aria-current="page">
        <svg class="bi me-2" width="16" height="16"></svg>
        {{pagesCamelCase[i]}}
      </a>
      <a *ngIf="selectPage != selectedPage" [routerLink]="'/'+selectPage" attr.data-cy="{{selectPage}}" class="nav-link text-white">
        <svg class="bi me-2" width="16" height="16"></svg>
        {{pagesCamelCase[i]}}
      </a>
    </li>
  </ul>
  <hr>

  <img class="logo" src="assets/logo-mauhn.png">
</div>
