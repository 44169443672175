export const environment = {
  production: true,
  electron: false,
  useEmulators: false,
  name: 'prod-browser',
  firebase : {
    apiKey: 'AIzaSyDU3RonnbturfaNgdlqx8I139zfXCQUkRk',
    authDomain: 'turboteach.firebaseapp.com',
    projectId: 'turboteach',
    storageBucket: 'turboteach.appspot.com',
    messagingSenderId: '599637822821',
    appId: '1:599637822821:web:06df4528b84e439f0ea58c'
  }
};
