import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment.dev.browser';
import {LoginComponent} from './user-management/login/login.component';
import {RegisterComponent} from './user-management/register/register.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ForgotPasswordComponent} from './user-management/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './user-management/verify-email/verify-email.component';
import {TrainingComponent} from './component/training/training.component';
import {RunComponent} from './component/run/run.component';
import {KeypadComponent} from './component/keypads/keypad.component';
import {RobotsComponent} from './component/robots/robots.component';
import {BrainsComponent} from './component/brains/brains.component';
import {AccountComponent} from './component/account/account.component';
import {BillingComponent} from './component/billing/billing.component';
import {LogoutComponent} from './component/logout/logout.component';
import {AngularFireAuthModule} from '@angular/fire/auth';

import { AcceptInvitationComponent } from './user-management/accept-invitation/accept-invitation.component';

import {RouterModule} from '@angular/router';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Overlay} from '@angular/cdk/overlay';
import { NotificationComponent } from './component/notification/notification.component';
import { SessionComponent } from './component/training/session/session.component';


import {USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/auth';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/firestore';
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/functions';
import { HistoryComponent } from './component/training/history/history.component';
import { UnifiedActionsComponent } from './user-management/unified-actions/unified-actions.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    TrainingComponent,
    RunComponent,
    KeypadComponent,
    RobotsComponent,
    BrainsComponent,
    AccountComponent,
    BillingComponent,
    LogoutComponent,
    AcceptInvitationComponent,
    NotificationComponent,
    SessionComponent,
    HistoryComponent,
    UnifiedActionsComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        FormsModule,
        RouterModule,
        MatProgressBarModule,
        NoopAnimationsModule,
        ReactiveFormsModule
    ],
  providers: [
    MatSnackBar,
    Overlay,
    {provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined},
    {provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined},
    {provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
