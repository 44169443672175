import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../shared/auth.service';
import {UserProfile} from '../../model/user-profile';
import {DataService} from '../../shared/data.service';
import {isOrganization, Organization} from '../../model/organization';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  // User profile stuff
  name = '';
  email: string | null | undefined = '';
  organizationNameTemp = '';
  downloadedUserProfile: UserProfile | undefined = undefined;

  // Organization stuff
  VAT = '';
  address = '';
  country = '';
  organizationName = '';
  invitedEmails: string[] = [];
  memberIds: string[] = [];
  hasVAT = true;
  downloadedOrganization: Organization | undefined = undefined;

  isEditUserProfile = true;

  // Team member stuff
  tempEmail = '';

  organizationState: OrganizationState = 'no organization';

  subscriptions: Subscription[] = [];
  trackingSnapshotChangesOrganization = false;

  loadingState = false;

  constructor(private authService: AuthService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        this.snapshotChangesUserProfile();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  changeTab() {
    this.isEditUserProfile = !this.isEditUserProfile;
  }

  snapshotChangesUserProfile() {
    this.email = this.authService.getEmail();

    this.subscriptions.push(this.dataService.userProfileSnapshotChanges().subscribe(data => {
      const userProfile: UserProfile = data.payload.data() as UserProfile;
      this.downloadedUserProfile = userProfile;
      this.name = userProfile.name;
      const organizationId = userProfile.organizationId;
      if (organizationId !== '') {
        this.snapshotChangesOrganization(organizationId);
      } else {
        this.organizationState = 'no organization';
      }
      console.log(userProfile);
    }, err => {
      alert(err.message);
    }));
  }


  snapshotChangesOrganization(organizationId: string) {
    if (this.trackingSnapshotChangesOrganization) {
      return;
    }
    this.trackingSnapshotChangesOrganization = true;

    this.subscriptions.push(this.dataService.getOrganization(organizationId).subscribe(data => {
      const organization: Organization = data.payload.data() as Organization;
      // Fill userProfile
      this.organizationNameTemp = organization.name;

      // Fill organization data
      this.downloadedOrganization = organization;
      this.VAT = organization.VAT;
      this.address = organization.address;
      this.country = organization.country;
      this.organizationName = organization.name;
      this.invitedEmails = organization.invitedEmails;
      this.memberIds = organization.memberIds;
      this.hasVAT = organization.hasVAT;

      if (organization.ownerUid === this.authService.getUserId()) {
        this.organizationState = 'admin';
      } else {
        this.organizationState = 'member';
        this.isEditUserProfile = true;
      }
    }, err => {
      alert(err.message);
    }));
  }

  updateUserName() {
    console.log('in save changes user');
    this.dataService.updateUserName(this.name).subscribe(res => {},
      error => {
      alert(error.message);
      });
  }

  createOrganization() {
    if (this.organizationName === '' || this.address === '' || this.country === '' || (this.hasVAT && this.VAT === '')) {
      alert('Please fill in all fields');
      return;
    }
    const organization: Organization = {VAT: this.VAT, address: this.address, country: this.country, hasVAT: this.hasVAT, id: '',
      invitedEmails: [], name: this.organizationName, ownerUid: '', memberIds: []};
    if (!isOrganization(organization)) {
      console.error(organization);
    }
    this.loadingState = true;
    this.dataService.addOrganization(organization).subscribe(res => {
      console.log('organization added');
      // @ts-ignore
      this.downloadedUserProfile.organizationId = res.id;
      this.downloadedOrganization = res;
      this.organizationNameTemp = res.name;
      this.loadingState = false;
    }, err => {
      alert(err.message);
      this.loadingState = false;
    });
  }

  saveChangesOrganization() {
    if (this.organizationName === '' || this.address === '' || this.country === '' || (this.hasVAT && this.VAT === '')) {
      alert('Please fill in all fields');
      return;
    }
    const organization: Organization = {VAT: this.VAT, address: this.address, country: this.country, hasVAT: this.hasVAT,
      id: this.downloadedOrganization ? this.downloadedOrganization.id : '',
      invitedEmails: this.invitedEmails, name: this.organizationName,
      ownerUid: this.downloadedOrganization ? this.downloadedOrganization.ownerUid : '',
    memberIds: this.memberIds};

    if (!isOrganization(organization)) {
      alert('Not a valid organization');
    }

    this.dataService.updateOrganization(organization).subscribe(response => {},
      error => {
        alert(error.message);
      });
  }

  removeTeamMember(index: number) {
    this.dataService.removeTeamMember(this.invitedEmails[index]).subscribe(organization => {
      this.downloadedOrganization = organization;
      // @ts-ignore
      this.snapshotChangesOrganization(this.downloadedOrganization.id);
    });
  }

  inviteTeamMember() {
    const validEmail = this.isValidEmail(this.tempEmail);
    if (validEmail) {
      // @ts-ignore
      this.dataService.sendInvitation(this.tempEmail, this.downloadedOrganization.id).subscribe(res => {},
        error => {
          alert(error.message);
        });
      this.tempEmail = '';
    } else {
      alert('Please provide a valid e-mail address');
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
}

export type OrganizationState = 'no organization' | 'admin' | 'member';
