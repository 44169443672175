<form>
<div class="mb-3">
  <table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Checkpoint Name</th>
    <th scope="col">Trainer</th>
    <th scope="col">ModelSize</th>
    <th scope="col">Robot</th>
    <th scope="col">Start training time</th>
    <th scope="col">Stop training time</th>
    <th scope="col">WandB Link</th>
    <th scope="col"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let checkpoint of checkpointsSortedAsHierarchy; index as i">
    <th scope="row">{{printTabs(checkpoint)}}{{checkpoint.brainName}}</th>
    <td>{{checkpoint.ownerName}}</td>
    <td>{{checkpoint.modelSize}}</td>
    <td>{{checkpoint.robot}}</td>
    <td>{{printTime(checkpoint.startTrainingSessionTime)}}</td>
    <td>{{printTime(checkpoint.stopTrainingSessionTime)}}</td>
<!--    TODO: remove ngIf as soon as backwards compatibility is not necessary anymore-->
    <td><button class="btn btn-info" *ngIf="checkpoint.wandbLink" (click)="copyToClipboard(checkpoint.wandbLink)">Copy link</button></td>
    <td><button (click)="prepareDelete(checkpoint)" class="btn btn-danger">Delete</button></td>
  </tr>
  </tbody>
</table>
</div>
</form>

<div *ngIf="showOverlay" class="overlay" tabindex="-1" aria-labelledby="deleteCheckpointModalLabel" aria-hidden="true">

  <div class="overlay-content">
    <div class="modal-header">
      <h5 class="modal-title" id="deleteCheckpointModalLabel">Confirm Deletion</h5>
      <button type="button" class="btn-close" (click)="reset()"></button>
    </div>
    <div class="modal-body">
      <p>
        Please type the name of the checkpoint to confirm deletion:
      </p>
      <input type="text" [placeholder]="selectedCheckpointName" [(ngModel)]="confirmationName" class="form-control">
      <div *ngIf="isNameIncorrect" style="text-align: left" class="text-danger mt-2">
        The name doesn't match!
      </div>
    </div>
    <br><br>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="reset()">Cancel</button>
      <button type="button" class="btn btn-danger" [disabled]="waitingForCallBack" (click)="confirmDeletion()">Delete</button>
    </div>
  </div>
</div>
