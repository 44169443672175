import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  email = '';
  password = '';
  passwordRepeat = '';
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.register();
    }
  }

  register() {
    if (this.email === '') {
      alert('Please enter email');
      return;
    }
    if (this.password === '') {
      alert('Please enter password');
      return;
    }
    if (this.passwordRepeat === '') {
      alert('Please enter your password to avoid typos');
      return;
    }
    if (this.passwordRepeat !== this.password) {
      alert('Passwords do not match');
      return;
    }

    this.authService.register(this.email, this.password);
    this.email = '';
    this.password = '';
    this.passwordRepeat = '';
  }
}
