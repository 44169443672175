import {Injectable} from '@angular/core';
import {ConfigurationService, OperatingSystem} from './configuration.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private configurationService: ConfigurationService,
              private storage: AngularFireStorage,
              private functions: AngularFireFunctions) { }

  async downloadApp() {
    let path = '';
    switch (this.configurationService.detectOperatingSystem()) {
      case OperatingSystem.WINDOWS: path = 'windows/TurboTeach.exe'; break;
      case OperatingSystem.LINUX: path = 'linux/TurboTeach.zip'; break;
      case OperatingSystem.MACOS: path = 'macos/TurboTeach.dmg'; break;
    }
    const getUrl: any = this.functions.httpsCallable('testFunction2');
    getUrl({path}).subscribe((url: any) => {
      console.log(url);
      window.open(url);
    });
  }
}
