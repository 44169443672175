import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brains',
  templateUrl: './brains.component.html',
  styleUrls: ['./brains.component.css']
})
export class BrainsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
