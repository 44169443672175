<div style="display: flex;">
  <app-dashboard></app-dashboard>
  <div class="content">
    <ul *ngIf="organizationState === 'no organization' || organizationState === 'admin'" class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isEditUserProfile}" (click)="changeTab()">User Profile</a>
      </li>
      <li class="nav-item">
        <a data-cy="nav-to-organization" class="nav-link" [ngClass]="{'active': !isEditUserProfile}" (click)="changeTab()">Organization</a>
      </li>
    </ul>

<!--    -->
<!--    Edit User-->
<!--    -->
    <form *ngIf="isEditUserProfile || organizationState === 'member'">
      <div class="mb-3">
        <h4 class="text-dark-muted">Edit User Profile</h4>
      </div>
      <div class="mb-3">

        <label class="form-label">Name</label>
        <input data-cy="name-field" type="email" class="form-control" name="name" [(ngModel)]="name">
      </div>
      <div class="mb-3">

        <label class="form-label">Email</label>
        <input type="email" class="form-control" name="email" [(ngModel)]="email" disabled>
      </div>
      <div class="mb-3">

        <label class="form-label">Organization</label>
        <input type="email" class="form-control" name="organization" [(ngModel)]="organizationNameTemp" disabled>
      </div>

      <button data-cy="btn-save-changes" type="button" class="btn btn-info" (click)="updateUserName()">Save Changes</button>
    </form>

<!--    -->
<!--    Create Organization-->
<!--    -->
    <form *ngIf="!isEditUserProfile && organizationNameTemp === ''">
      <div class="mb-3">
        <h4 class="text-dark-muted">Create Organization</h4>
      </div>

      <div class="mb-3">
        <label class="form-label">Name</label>
        <input type="organizationName" class="form-control" name="organizationName" [(ngModel)]="organizationName">
      </div>

      <div class="mb-3">
        <label class="form-label">Address</label>
        <input type="address" class="form-control" name="address" [(ngModel)]="address">
      </div>
      <div class="mb-3">
        <label class="form-label">Country</label>
        <input type="country" class="form-control" name="country" [(ngModel)]="country">
      </div>

      <div class="mb-3">
        <label class="form-label">The organization has a VAT number:</label>
        <br>
        <div class="form-label btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [(ngModel)]="hasVAT" [value]="false">
          <label class="btn btn-outline-primary" for="btnradio1">Off</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [(ngModel)]="hasVAT" [value]="true">
          <label class="btn btn-outline-primary" for="btnradio2">On</label>
        </div>
      </div>

      <div class="mb-3" *ngIf="hasVAT">
        <label class="form-label">VAT number</label>
        <input type="VAT" class="form-control" name="organization" [(ngModel)]="VAT">
      </div>

      <button type="button" class="btn btn-info" (click)="createOrganization()" [disabled]="loadingState">Save Changes</button>
    </form>

<!--    -->
<!--    Edit Organization-->
<!--    -->
    <form *ngIf="!isEditUserProfile && organizationNameTemp !== ''">
      <div class="mb-3">
        <h4 class="text-dark-muted">Edit Organization</h4>
      </div>

      <div class="mb-3">
        <label class="form-label">Name</label>
        <input type="organizationName" class="form-control" name="organizationName" [(ngModel)]="organizationName">
      </div>

      <div class="mb-3">
        <label class="form-label">Address</label>
        <input type="address" class="form-control" name="address" [(ngModel)]="address">
      </div>
      <div class="mb-3">
        <label class="form-label">Country</label>
        <input type="country" class="form-control" name="country" [(ngModel)]="country">
      </div>

      <div class="mb-3">
        <label class="form-label">The organization has a VAT number:</label>
        <br>
        <div class="form-label btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" [(ngModel)]="hasVAT" [value]="false">
          <label class="btn btn-outline-primary" for="btnradio3">Off</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" [(ngModel)]="hasVAT" [value]="true">
          <label class="btn btn-outline-primary" for="btnradio4">On</label>
        </div>
      </div>

      <div class="mb-3" *ngIf="hasVAT">
        <label class="form-label">VAT number</label>
        <input type="VAT" class="form-control" name="organization" [(ngModel)]="VAT">
      </div>

      <button type="button" class="btn btn-info" (click)="saveChangesOrganization()">Save Changes</button>

      <br><br><br><br><br>

      <div class="mb-3">
        <h4 class="text-dark-muted">Team Members</h4>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Email</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let k of invitedEmails; index as i">
          <th scope="row">{{invitedEmails[i]}}</th>
          <td><button data-cy="remove-team-member" type="button" class="btn btn-info" [disabled]="k==email" (click)="removeTeamMember(i)">Remove Team Member</button></td>
        </tr>
        <tr>
          <th scope="row"><input name="second" [(ngModel)]="tempEmail" data-cy="input-team-member" placeholder="name@domain.com" />
          </th>
          <td><button data-cy="btn-invite" type="button" class="btn btn-info" (click)="inviteTeamMember()">Invite</button></td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
