import { Component, OnInit } from '@angular/core';
import {PythonServerService} from '../../shared/python-server.service';
import {ConfigurationService} from '../../shared/configuration.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {StorageService} from '../../shared/storage.service';

@Component({
  selector: 'app-robots',
  templateUrl: './robots.component.html',
  styleUrls: ['./robots.component.css']
})
export class RobotsComponent implements OnInit {
  serverStarted = false;
  isElectron = false;

  constructor(private pythonServerService: PythonServerService,
              private configurationService: ConfigurationService,
              private storageService: StorageService) {
    this.pythonServerService.cloudServerStarted.subscribe(serverStarted => {
      this.serverStarted = serverStarted;
    });
    this.isElectron = this.configurationService.isElectron();
  }

  ngOnInit(): void {
  }

  downloadRobotSoftware() {
    this.storageService.downloadApp();
  }
}
