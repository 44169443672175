import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/data.service';
import {Keypad} from '../../model/keypad';
import {AuthService} from '../../shared/auth.service';
import {Subscription} from 'rxjs';
import {BrainSize, ConfigurationService, RunType, TrainingState} from '../../shared/configuration.service';
import {StorageService} from '../../shared/storage.service';
import {PythonServerService} from '../../shared/python-server.service';

import {CategoryScale, LinearScale, LineController, LineElement, PointElement} from 'chart.js';
import {SocketService} from '../../shared/socket.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Checkpoint, printCheckpoint} from '../../model/checkpoint';
import {Chart} from 'chart.js/auto';
import {RobotService} from '../../shared/robot.service';
import {Robot} from '../../model/robot';
import {ArgumentsService} from '../../shared/arguments.service';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit, OnDestroy {
  runTypes: RunType[] = ['Start new brain', 'Use existing brain'];
  brainSizes: BrainSize[] = ['xsmall', 'small', 'medium', 'large', 'xlarge'];

  keypads: Keypad[] = [];

  subscriptions: Subscription[] = [];

  isCurrentSessionActive = true;

  checkpoints: Checkpoint[] = [];

  robots: Robot[] = [];
  useSameRobotAsExistingCheckpoint = this.configurationService.useSameRobotAsExistingCheckpoint;


  public readonly TrainingState = TrainingState;

  constructor(private dataService: DataService,
              private authService: AuthService,
              private configurationService: ConfigurationService,
              private storageService: StorageService,
              private pythonServerService: PythonServerService,
              private socketService: SocketService,
              private snackBar: MatSnackBar,
              private robotService: RobotService,
              private argumentService: ArgumentsService) {
    this.socketService.replayBufferUploaded.subscribe(replayBuffer => {
      if (replayBuffer) {
        this.manageCheckpoint().then(r => {});
      }
    });
    this.socketService.modelUploaded.subscribe(modelUploaded => {
      if (modelUploaded) {
        this.manageCheckpoint().then(r => {});
      }
    });
  }

  printCheckpoint(checkpoint: Checkpoint) {
    return printCheckpoint(checkpoint);
  }

  getAllCheckpoints() {
    this.dataService.getAllCheckpointsOrganization().subscribe(checkpoints => {
      // Keep track of the checkpoints
      this.checkpoints = checkpoints;

      // Sort checkpoints, most recent first
      this.checkpoints = this.checkpoints.sort((a, b) => {
        return parseFloat(b.startTrainingSessionTime) - parseFloat(a.startTrainingSessionTime);
      });

      // If none is selected, select first
      if (this.selectedExistingCheckpoint === undefined) {
        this.configurationService.selectedExistingCheckpoint = this.checkpoints[0];
      }
    });
  }

  checkBrainName() {
    // Check brainName
    const brainName = this.configurationService.currentBrainName;
    if (!brainName) {
      alert('brainName must be defined');
      throw new Error('BrainName must be defined');
    }
    return this.configurationService.currentBrainName;
  }

  async createCheckpoint() {
    // Set properties that depend on existing checkpoint to start from
    let existingCheckpointId: string ;
    let modelSize: string;
    if (this.configurationService.currentRunType !== 'Start new brain') {
      if (!this.selectedExistingCheckpoint) {
        alert('the user is trying to start from an existing checkpoint, but none was selected');
        return;
      }
      existingCheckpointId = this.selectedExistingCheckpoint.id;
      modelSize = this.selectedExistingCheckpoint.modelSize;
    } else {
      existingCheckpointId = '';
      modelSize = this.configurationService.currentBrainSize;
    }

    const brainName = this.checkBrainName();

    // Fetch wandb
    const wandbLink = await this.socketService.requestWandbLink();

    // Create checkpoint
    const checkpoint: Checkpoint = {
      brainName,
      id: '',
      modelSize,
      startTrainingSessionTime: '',
      stopTrainingSessionTime: '',
      startedFromCheckpointId: '',
      uuid: this.configurationService.uuid,
      paused: [],
      unpaused: [],
      accessType: 'organization',
      ownerId: '',
      ownerName: '',
      organizationId: '',
      robot: this.currentRobot.name,
      wandbLink
    };

    // Set active checkpoint
    this.configurationService.activeCheckpoint = checkpoint;

    // Upload checkpoint
    if (existingCheckpointId) {
      this.dataService.createCheckpointFromExistingCheckpoint(checkpoint, existingCheckpointId).subscribe(result => {
        // Important for updating the checkpoint id
        this.configurationService.activeCheckpoint = result;
      });
    } else {
      this.dataService.createCheckpointFromScratch(checkpoint).subscribe(result => {
        // Important for updating the checkpoint id
        this.configurationService.activeCheckpoint = result;
      });
    }
  }

  async manageCheckpoint() {
    /////////////////////////////////////////////////////
    // Check if back-end uploaded the checkpoint to S3
    /////////////////////////////////////////////////////

    // Check if both replay buffer and model have been uploaded
    if (!this.socketService.modelUploaded.value || !this.socketService.replayBufferUploaded.value) {
      return;
    }

    // Reset modelUploaded and replayBuffer flags
    this.socketService.modelUploaded.next(false);
    this.socketService.replayBufferUploaded.next(false);

    if (this.socketService.timeoutIdCheckpointUploaded) {
      // If the GUI was expecting an upload to happen, turn off the alert that would be triggered after timeout of this expectation
      clearTimeout(this.socketService.timeoutIdCheckpointUploaded);
      this.socketService.timeoutIdCheckpointUploaded = undefined;
    }

    /////////////////////////////////////////////////////
    // Case 1: just update the stopTrainingTime of the checkpoint
    /////////////////////////////////////////////////////
    if (this.configurationService.activeCheckpoint) {
      // Update stopTrainingTime
      console.log(this.configurationService.activeCheckpoint.id);
      this.dataService.updateStopTrainingTimeCheckpoint(this.configurationService.activeCheckpoint.id).subscribe(result => {});
    } else {
      /////////////////////////////////////////////////////
      // Case 2: the checkpoint is new => create it
      /////////////////////////////////////////////////////

      await this.createCheckpoint();
    }

    /////////////////////////////////////////////////////
    // Show that the checkpoint has been updated
    /////////////////////////////////////////////////////

    this.snackBar.open('Brain has been saved', 'Close', {
      duration: 8000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['my-snackbar-class']
    });
  }

  getImagePath() {
    return 'assets/images/' + this.currentRobot.imageData;
  }

  public get trainingState(): TrainingState {
    return this.configurationService.trainingState.value;
  }

  public get runActorInCloud() {
    return this.configurationService.runActorInCloud;
  }

  public set runActorInCloud(runActorInCloud: boolean) {
    this.configurationService.runActorInCloud = runActorInCloud;
  }

  public get currentBrainName() {
    return this.configurationService.currentBrainName;
  }

  public set currentBrainName(currentBrainName: string) {
    this.configurationService.currentBrainName = currentBrainName;
  }

  public get isElectron() {
    return this.configurationService.isElectron();
  }

  public get currentRunType() {
    return this.configurationService.currentRunType;
  }

  public get currentRobot(): Robot {
    return this.configurationService.currentRobot;
  }

  public get currentBrainSize() {
    return this.configurationService.currentBrainSize;
  }

  public get selectedExistingCheckpoint() {
    return this.configurationService.selectedExistingCheckpoint;
  }

  public get usePackagedPython() {
    return this.configurationService.usePackagedPython;
  }

  public get argumentString() {
    return this.argumentService.argumentString;
  }

  public set argumentString(value: any) {
    this.argumentService.argumentString = value;
  }

  public get branch() {
    return this.configurationService.branch;
  }

  public set branch(value: any) {
    this.configurationService.branch = value;
  }

  public get progress() {
    return this.pythonServerService.progress;
  }

  public get ec2instanceId() {
    return this.pythonServerService.ec2instanceId.value;
  }

  public get startTime() {
    return this.pythonServerService.startTime;
  }

  public get expectedStartTrainingSession() {
    return this.pythonServerService.expectedStartTrainingSession;
  }

  public get isProduction() {
    return this.configurationService.isProduction();
  }

  public get localServerRunning() {
    return this.pythonServerService.localServerStarted;
  }

  public get argumentStringClient() {
    return this.argumentService.argumentStringActor;
  }

  public get argumentStringClientStatus() {
    return this.argumentService.argumentStringClientStatus;
  }

  toggleUseSameRobotAsExistingCheckpoint() {
    this.configurationService.useSameRobotAsExistingCheckpoint = !this.configurationService.useSameRobotAsExistingCheckpoint;
    this.argumentService.updateArgumentString();
    this.useSameRobotAsExistingCheckpoint = this.configurationService.useSameRobotAsExistingCheckpoint;
  }

  getAllKeypads() {
    this.subscriptions.push(this.dataService.getUserKeypadObservable().subscribe(changeDetected => {
      this.dataService.getUserKeypads().subscribe(res => {
        this.keypads = res;
        const keypad: Keypad = {
          description: '', id: '', keys: [], name: 'Standard Feedback Config', texts: [], voiceRecordButton: false,
          uid: ''
        };
        this.keypads.push(keypad);
        this.configurationService.currentKeypad = keypad;
      }, err => {
        alert(err.message);
      });
    }));
  }

  selectRunActorInCloud(runActorInCloud: boolean) {
    this.runActorInCloud = runActorInCloud;
    this.robots = this.robotService.getRobots(this.isProduction, this.runActorInCloud);
    if (this.robots.indexOf(this.currentRobot) === -1) {
      this.configurationService.currentRobot = this.robots[0];
    }
    this.updateArgumentString();
  }

  selectCurrentRunType(s: RunType): void {
    this.configurationService.currentRunType = s;
    this.updateArgumentString();
  }

  selectExistingCheckpoint(checkpoint: Checkpoint): void {
    this.configurationService.selectedExistingCheckpoint = checkpoint;
    this.configurationService.currentBrainSize = checkpoint.modelSize;
    this.updateArgumentString();
  }

  selectCurrentBrainSize(s: string): void {
    this.configurationService.currentBrainSize = s;
    this.updateArgumentString();
  }

  selectCurrentRobot(robot: Robot): void {
    this.configurationService.currentRobot = robot;
    this.updateArgumentString();
  }

  selectKeypad(keypad: Keypad): void {
    this.configurationService.currentKeypad = keypad;
    this.updateArgumentString();
  }

  updateArgumentString(): void {
    this.argumentService.updateArgumentString();
  }

  changeTab(): void {
    this.isCurrentSessionActive = !this.isCurrentSessionActive;
  }

  downloadSoftware() {
    this.storageService.downloadApp();
  }

  startFakeLearner(): void {
    this.configurationService.trainingState.next(TrainingState.WAITING_FOR_CLOUD_SERVER);
    this.pythonServerService.guiServerPublicDnsName.next('localhost');
  }

  startLearner(): void {
    this.checkBrainName(); // brain name must not be empty

    this.configurationService.trainingState.next(TrainingState.WAITING_FOR_CLOUD_SERVER);

    // Set a checkpoint uuid if we are continuing a run
    let checkpointUuid: undefined | string;
    if (this.currentRunType !== 'Start new brain') {

      if (this.selectedExistingCheckpoint === undefined) {
        alert('Failed check out checkpoint - current checkpoint undefined');
        return;
      }

      checkpointUuid = this.selectedExistingCheckpoint.uuid;
    }

    this.pythonServerService.startLearner(checkpointUuid);
  }

  stopCloudServer(): void {
    this.pythonServerService.stopCloudServer();
    this.configurationService.trainingState.next(TrainingState.NO_TRAINING);
  }

  startLocalServer(): void {
    this.pythonServerService.startLocalPythonServerIfLocalActor();
  }

  stopLocalServer(): void {
    this.pythonServerService.stopLocalPythonServerIfLocalActor();
  }

  togglePackagedPython() {
    this.configurationService.usePackagedPython = !this.configurationService.usePackagedPython;
  }

  ngOnInit() {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        this.getAllKeypads();
        this.subscriptions.push(this.dataService.userProfileSnapshotChanges().subscribe(res => {
          this.getAllCheckpoints();
        }));
      }
    }));
    this.robots = this.robotService.getRobots(this.isProduction, this.runActorInCloud);
    this.configurationService.currentRobot = this.robots[0];
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
