<div style="display: flex;">
  <app-dashboard></app-dashboard>

  <div class="content">

    <ul class="nav nav-tabs" *ngIf="trainingState === TrainingState.NO_TRAINING || trainingState ===
     TrainingState.WAITING_FOR_CLOUD_SERVER">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isCurrentSessionActive}" (click)="changeTab()">Current Session</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': !isCurrentSessionActive}" (click)="changeTab()"
        data-cy="btn-History Training Sessions">History Training
            Sessions</a>
      </li>
    </ul>

    <form *ngIf="isCurrentSessionActive && (trainingState === TrainingState.NO_TRAINING || trainingState ===
    TrainingState.WAITING_FOR_CLOUD_SERVER)">

      <div class="mb-3">
        <h4 class="text-dark-muted">Start Training Session</h4>
      </div>
      <div class="mb-3" title="The brain is an artificial neural network, containing the robot's knowledge and
      reasoning skills. You can start from scratch or continue using an already trained neural network. See the
      Neural Networks tab for more information.">
        <label class="form-label">Brain</label>
        <div class="dropdown">
          <button class="dropdown-toggle custom-dropdown form-control" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false" data-cy="brain-dropdown-button">
            <span class="dropdown-text">{{currentRunType}}</span>
            <span class="dropdown-arrow">
      <i class="bi bi-caret-down-fill"></i>
    </span>
          </button>
          <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenuButton">
            <li *ngFor="let option of runTypes"><a class="dropdown-item" [attr.data-cy]="'brain-option-' + option"
                                                   (click)="selectCurrentRunType(option)">{{option}}</a></li>
          </ul>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Brain Name</label>
        <input type="currentBrainName" class="form-control" data-cy="brain-name" name="currentBrainName"
               placeholder="E.g. 'First test', 'Fridge Finder', ..." [(ngModel)]="currentBrainName">
      </div>


      <div *ngIf="currentRunType==='Start new brain'" class="mb-3" title="The size of the brain. Bigger means capable
      of more complex tasks. Smaller means faster reaction times. Medium is recommended for most applications.">
        <label class="form-label">Brain Size</label>
        <div class="dropdown">
          <button class="dropdown-toggle custom-dropdown form-control" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false" data-cy="button-dropdown-size">
            <span class="dropdown-text">{{currentBrainSize}}</span>
            <span class="dropdown-arrow">
      <i class="bi bi-caret-down-fill"></i>
    </span>
          </button>
          <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenuButton">
            <li *ngFor="let option of brainSizes"><a class="dropdown-item"
                                                     [attr.data-cy]="'modelsize-' + option" (click)="selectCurrentBrainSize(option)">
                {{option}}</a></li>
          </ul>
        </div>
      </div>

      <div class="mb-3" *ngIf="isElectron">
        <label class="form-label">Run the model in the Cloud</label>
        <br>
        <p>For highest speed: run locally if you have access to a GPU. Otherwise, run in the Cloud.</p>
        <div class="dropdown">
          <button class="dropdown-toggle custom-dropdown form-control" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            <span class="dropdown-text">{{runActorInCloud ? 'Cloud' : 'Local'}}</span>
            <span class="dropdown-arrow">
      <i class="bi bi-caret-down-fill"></i>
    </span>
          </button>
          <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" (click)="selectRunActorInCloud(true)">Cloud</a></li>
            <li><a class="dropdown-item" (click)="selectRunActorInCloud(false)">Local</a></li>
          </ul>
        </div>
      </div>


      <div *ngIf="currentRunType!='Start new brain'" class="mb-3" title="The size of the brain. Bigger means capable of
      more complex tasks. Smaller means faster reaction times. Medium is recommended for most applications.">
        <label class="form-label">Existing Checkpoint</label>
        <div class="dropdown">
          <button class="dropdown-toggle custom-dropdown form-control" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            <span class="dropdown-text">{{selectedExistingCheckpoint ? printCheckpoint(selectedExistingCheckpoint) :
                ''}}</span>
            <span class="dropdown-arrow">
      <i class="bi bi-caret-down-fill"></i>
    </span>
          </button>
          <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenuButton">
            <li *ngFor="let option of checkpoints"><a class="dropdown-item" (click)="selectExistingCheckpoint(option)">
              {{printCheckpoint(option)}}</a></li>
          </ul>
        </div>
      </div>


      <div class="mb-3">
        <label class="form-label">Robot</label>
          <div *ngIf="currentRunType==='Use existing brain'">

              <div *ngIf="useSameRobotAsExistingCheckpoint">
                  <input type="checkbox" [checked]="useSameRobotAsExistingCheckpoint" (change)="toggleUseSameRobotAsExistingCheckpoint()">
                  Continue with same robot: {{currentRobot.name}}
              </div>
              <div *ngIf="!useSameRobotAsExistingCheckpoint">
                  <input type="checkbox" [checked]="useSameRobotAsExistingCheckpoint" (change)="toggleUseSameRobotAsExistingCheckpoint()">
                  <span style="text-decoration: line-through">
                      Continue with same robot
                  </span>
                  <div class="alert alert-warning">
                      WARNING: Switching robots should be done with care!
                  </div>
              </div>
          </div>
        <div *ngIf="currentRunType!=='Use existing brain' || !useSameRobotAsExistingCheckpoint" class="dropdown">
          <button class="dropdown-toggle custom-dropdown form-control" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false" data-cy="dropdown-robot-menu">
            <span class="dropdown-text">{{currentRobot.name}}</span>
            <span class="dropdown-arrow">
      <i class="bi bi-caret-down-fill"></i>
    </span>
          </button>
          <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenuButton">
            <li *ngFor="let option of robots">
              <a class="dropdown-item" (click)="selectCurrentRobot(option)"
                 [attr.data-cy]="'robot-' + option.name">{{option.name}}</a>
            </li>
          </ul>
        </div>
        <div>
          <br>
          <img style="width:200px; height:200px;" [src]="getImagePath()">
          <br>
          <p>{{currentRobot.description}}</p>
        </div>
      </div>

      <div *ngIf="!isProduction" class="mb-3">
        <label class="form-label">Server branch to be checked out</label>
        <input type="email" class="form-control" data-cy="select-branch" name="branch" [(ngModel)]="branch">
      </div>

      <div *ngIf="!isProduction && !runActorInCloud">
        <br>
        <div *ngIf="usePackagedPython">
          <button  type="button" class="btn btn-info" (click)="togglePackagedPython()">Using Packaged Dreamer</button>
          Run "npm run build:dreamer" each time you modify your python code + (re)start the local server
        </div>
        <div *ngIf="!usePackagedPython">
          <button type="button" class="btn btn-info" (click)="togglePackagedPython()">Run your own code</button>
          You are responsible for running the local server with this config:<br>
          <div *ngIf="argumentStringClientStatus==='valid'">{{argumentStringClient}}</div>
          <div *ngIf="argumentStringClientStatus!=='valid'">{{argumentStringClientStatus}}</div>
        </div>
        <br>
        You can keep on using the same cloud server also after restarting the local server.
          <br>
      </div>
        <br>
      <button *ngIf="ec2instanceId.length===0" [disabled]="trainingState === TrainingState.WAITING_FOR_CLOUD_SERVER"
              type="button" class="btn btn-info" (click)="startLearner()" data-cy="btn-Start Training Session">Start Training Session</button>
      <button *ngIf="ec2instanceId.length!==0" type="button" class="btn btn-outline-danger"
              (click)="stopCloudServer()">Stop Training Session</button>

      <button *ngIf="!isProduction" [disabled]="trainingState === TrainingState.WAITING_FOR_CLOUD_SERVER" type="button"
              class="btn btn-info" (click)="startFakeLearner()">Start Fake Training Session</button>

<br>


      <br>
      <button *ngIf="!isProduction && !runActorInCloud && !localServerRunning" type="button" class="btn btn-info"
              (click)="startLocalServer()">Start Local Server</button>
      <button *ngIf="!isProduction && !runActorInCloud && localServerRunning" type="button"
              class="btn btn-outline-danger" (click)="stopLocalServer()">Stop Local Server</button>
      <br>

      <div *ngIf="trainingState === TrainingState.WAITING_FOR_CLOUD_SERVER" class="mb-3">
        <div class="overlay">
          <div class="overlay-content">
            <h2> Loading... </h2>
            <p>Loading will take 3 minutes</p>
            <br>
            <p>While waiting, check out our tutorial videos</p>
            <br>
            <mat-progress-bar class="progress-bar" mode="determinate" [value]="progress"></mat-progress-bar>
            <br>
            <button *ngIf="ec2instanceId.length!==0" type="button" class="btn btn-outline-danger" (click)="stopCloudServer()">Cancel Training Session</button>
            <br>
            <div *ngIf="argumentStringClientStatus === 'valid'">
                {{argumentStringClient}}
            </div>
          </div>
        </div>
      </div>
    </form>

    <div [hidden]="trainingState === TrainingState.NO_TRAINING || trainingState ===
    TrainingState.WAITING_FOR_CLOUD_SERVER">
      <app-session></app-session>
    </div>

    <div *ngIf="!isCurrentSessionActive && (trainingState === TrainingState.NO_TRAINING || trainingState ===
     TrainingState.WAITING_FOR_CLOUD_SERVER)">
      <app-history></app-history>
    </div>


<!--    <form *ngIf="isCurrentSessionActive && !isElectron && (trainingState === TrainingState.NO_TRAINING || trainingState-->
<!--     ===-->
<!--     TrainingState.WAITING_FOR_CLOUD_SERVER)">-->
<!--      <div class="mb-3">-->
<!--        <h4 class="text-dark-muted">Download Software</h4>-->
<!--      </div>-->
<!--      <p>Download the software to launch a Training Session.</p>-->
<!--      <button type="button" class="btn btn-info" (click)="downloadSoftware()">Download</button>-->
<!--    </form>-->
  </div>
</div>
