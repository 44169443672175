import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './user-management/login/login.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {RegisterComponent} from './user-management/register/register.component';
import {VerifyEmailComponent} from './user-management/verify-email/verify-email.component';
import {ForgotPasswordComponent} from './user-management/forgot-password/forgot-password.component';
import {TrainingComponent} from './component/training/training.component';
import {RunComponent} from './component/run/run.component';
import {KeypadComponent} from './component/keypads/keypad.component';
import {RobotsComponent} from './component/robots/robots.component';
import {BrainsComponent} from './component/brains/brains.component';
import {AccountComponent} from './component/account/account.component';
import {BillingComponent} from './component/billing/billing.component';
import {LogoutComponent} from './component/logout/logout.component';
import {AcceptInvitationComponent} from './user-management/accept-invitation/accept-invitation.component';
import {NotificationComponent} from './component/notification/notification.component';
import {UnifiedActionsComponent} from './user-management/unified-actions/unified-actions.component';

const routes: Routes = [
  {path: '', redirectTo: 'training', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'verify-email', component: VerifyEmailComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'training', component: TrainingComponent},
  {path: 'run', component: RunComponent},
  {path: 'keypads', component: KeypadComponent},
  {path: 'robots', component: RobotsComponent},
  {path: 'brains', component: BrainsComponent},
  {path: 'account', component: AccountComponent},
  {path: 'billing', component: BillingComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'accept-invitation', component: AcceptInvitationComponent},
  {path: 'notification', component: NotificationComponent},
  {path: 'app-unified-actions', component: UnifiedActionsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
