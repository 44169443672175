import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  forgotPassword(): void {
    this.authService.forgotPassword(this.email);
    this.email = '';
  }
}
