import {Injectable} from '@angular/core';
import {ConfigurationService, TrainingState} from './configuration.service';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/functions';
import {take} from 'rxjs/operators';
import {RobotService} from './robot.service';
import {ArgumentsService} from './arguments.service';

@Injectable({
  providedIn: 'root'
})
export class PythonServerService {
  bootTimeMinutes = 5;
  deadlineRetrieveInstance = 5;
  heartBeatMinutes = 3;

  cloudServerStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  localServerStarted = false;

  ec2instanceId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  timeoutId: any;
  learnerPublicDnsName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  guiServerPublicDnsName: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  startTime: Date = new Date();
  expectedStartTrainingSession: Date = new Date();

  progressSubscription: Subscription | undefined;

  progress = 0;
  errorFetchingLogsCounter = 0;
  errorScriptNotRunningCounter = 0;
  errorNoLogOutputReceivedCounter = 0;

  constructor(private configurationService: ConfigurationService,
              private functions: AngularFireFunctions,
              private argumentService: ArgumentsService) {

    // Update the timer every `heartBeatMinutes`
    setInterval(() => this.sendHeartbeat(), this.heartBeatMinutes * 60 * 1000);

    // Check for crash on Cloud every 30 seconds
    setInterval(() => this.checkCloudErrors(), 30 * 1000);

    this.learnerPublicDnsName.subscribe(learnerPublicDnsName => {
      this.argumentService.learnerPublicDnsName = learnerPublicDnsName;
      this.argumentService.updateArgumentString();
    });
  }

  checkCloudErrors() {
    if (this.ec2instanceId.value !== '') {
      const instanceId = this.ec2instanceId.value;
      const callable = this.functions.httpsCallable('fetchLogs');

      callable({instanceId}).subscribe(responseAny => {
        const response: { instanceState: string, logTail: string, scriptRunning: boolean, errorMessage: string } = responseAny;
        if (response.errorMessage.includes('No log output received')) {
          this.errorNoLogOutputReceivedCounter++;
          if (this.errorNoLogOutputReceivedCounter > 2) {
            alert('This is the ' + this.errorNoLogOutputReceivedCounter + 'th time this error occurs:\n' + response.errorMessage);
          }
        } else if (response.errorMessage.includes('not in a valid state for account')) {
          this.errorFetchingLogsCounter++;
          if (this.errorFetchingLogsCounter > 2) {
            alert('This is the ' + this.errorFetchingLogsCounter + 'th this error occurs:\n' + response.errorMessage);
          }
        } else if (response.instanceState !== 'running') {
          console.log('Cloud server status: ' + response.instanceState);
        } else {
          const responseString: string = response.logTail;
          if (responseString.includes('Process finished with exit code') || responseString.includes('Terminated')) {
            alert('CLOUD SERVER CRASHED\n\nCheck responseString in the console log');
            console.error({responseString});
          } else {
            if (!response.scriptRunning) {
              this.errorScriptNotRunningCounter++;
              if (this.errorScriptNotRunningCounter > 4) {
                alert('Cloud server still not running - this is taking longer than normal...');
              }
            }
            console.log({responseString});
          }
        }
      }, error => {
        alert(error.message);
      });
    }
  }

  sendHeartbeat() {
    if (this.ec2instanceId.value !== '') {
      const instanceId = this.ec2instanceId.value;
      const callable = this.functions.httpsCallable('updateTimer');
      callable({instanceId}).subscribe();
      console.log('heartbeat sent');
    }
  }

  startProgress() {
    const totalDuration = this.bootTimeMinutes * 60 * 1000; // bootTimeMinutes in milliseconds
    const intervalDuration = 1000; // Update every second

    // Calculate how much to increment each second
    const increment = (100 * intervalDuration) / totalDuration;

    // Create an interval Observable that emits a value every second and completes after 10 minutes
    const progressInterval$ = interval(intervalDuration).pipe(take(totalDuration / intervalDuration));

    this.progressSubscription = progressInterval$.subscribe(() => {
      this.progress += increment;

      // Ensure the progress doesn't exceed 100
      this.progress = Math.min(this.progress, 100);
    });
  }

  startLocalPythonServerIfLocalActor(): void {
    if (this.configurationService.runActorInCloud) {
      console.log('Skipping launch of local python script, cloud mode');
      return;
    }
    const clientArgumentString = this.argumentService.argumentStringActor;

    if (!this.configurationService.usePackagedPython) {
      return;
    }
    const data = {argumentString: clientArgumentString};
    // this.configurationService.getIpcRenderer().send('start-local-python-server', data);
    this.localServerStarted = true;
  }

  stopLocalPythonServerIfLocalActor(): void {
    if (this.configurationService.runActorInCloud) {
      console.log('Skipping termination of local python script, cloud mode');
      return;
    }
    if (this.configurationService.usePackagedPython) {
      this.configurationService.getIpcRenderer().send('stop-local-python-server');
      this.localServerStarted = false;
    }
  }

  async startLearner(checkpointUuid: undefined | string) {
    const serverArgumentString = this.configurationService.runActorInCloud ? this.argumentService.argumentStringActorAndLeaner :
      this.argumentService.argumentStringLearner;
    const data = {
      sourceUuid: checkpointUuid,
      destinationUuid: this.configurationService.uuid,
      argumentString: serverArgumentString,
      branch: this.configurationService.branch
    };

    try {
      // Start learner server
      const startLearnerFunction = this.functions.httpsCallable('startLearnerServer', {
        timeout: this.deadlineRetrieveInstance * 60 * 1000 // in millis
      });
      this.ec2instanceId.next(await startLearnerFunction(data).toPromise());
      console.log(`Started learner with instanceId ${this.ec2instanceId.value}`);

      // Check if running and start local actor server
      // TODO should probably be moved?
      this.checkInstanceInitialization();
    } catch (error) {
      console.error('Error while calling startLearnerServer function: ', error);
      this.configurationService.trainingState.next(TrainingState.NO_TRAINING);
    }
  }

  checkInstanceInitialization() {
    this.progress = 0;
    this.startTime = new Date();
    this.expectedStartTrainingSession = new Date(this.startTime.getTime()); // Creating a new Date object
    this.expectedStartTrainingSession.setMinutes(this.startTime.getMinutes() + this.bootTimeMinutes);
    this.startProgress();
    this.timeoutId = setInterval(() => {
      if (this.ec2instanceId.value === '') {
        clearInterval(this.timeoutId);
        return;
      }
      const callable = this.functions.httpsCallable('checkInstance');
      callable({instanceId: this.ec2instanceId.value}).subscribe(result => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - this.startTime.getTime();
        console.log(result);

        if (elapsedTime >= this.deadlineRetrieveInstance * 60 * 1000 && result === '') {
          clearInterval(this.timeoutId);
          alert('The Cloud server failed to initialize within 3 minutes. Training session is aborted. Please try again. ' +
            'If the problem persists, contact our support team.');
          this.stopCloudServer().then(r => {});
        } else if (result === '') {
          console.log('waiting for initialized server...');
        } else {
          this.learnerPublicDnsName.next(result);
          if (this.configurationService.runActorInCloud) {
            this.guiServerPublicDnsName.next(result);
          } else {
            this.guiServerPublicDnsName.next('localhost');
          }
          this.argumentService.updateArgumentString();
          clearInterval(this.timeoutId);
          this.startLocalPythonServerIfLocalActor();
        }
      });
    }, 5000);
  }

  async stopCloudServer(): Promise<void> {
    console.log('In stop server');
    this.progressSubscription?.unsubscribe();
    const terminateEC2InstanceFunction = this.functions.httpsCallable('terminateEC2Instance');
    try {
      const ec2instanceId = this.ec2instanceId.value;
      this.ec2instanceId.next('');
      this.learnerPublicDnsName.next('');
      const result = await terminateEC2InstanceFunction({instanceId: ec2instanceId}).toPromise();
      console.log('Successfully terminated instance');
      console.log(result);
    } catch (error) {
      console.error('Error terminating instance: ', error);
    }
    this.stopLocalPythonServerIfLocalActor();
  }
}
