import Utils from '../shared/utils';

export interface Organization {
  id: string;
  ownerUid: string;

  name: string;

  address: string;
  country: string;

  hasVAT: boolean;
  VAT: string;

  invitedEmails: string[];
  memberIds: string[];
}

export function isOrganization(obj: any): obj is Organization {
  return (
    typeof obj === 'object' &&
    'id' in obj && typeof obj.id === 'string' &&
    'ownerUid' in obj && typeof obj.ownerUid === 'string' &&
    'name' in obj && typeof obj.name === 'string' &&
    'address' in obj && typeof obj.address === 'string' &&
    'country' in obj && typeof obj.country === 'string' &&
    'hasVAT' in obj && typeof obj.hasVAT === 'boolean' &&
    'VAT' in obj && typeof obj.VAT === 'string' &&
    'invitedEmails' in obj && Utils.isStringArray(obj.invitedEmails) &&
    'memberIds' in obj && Utils.isStringArray(obj.memberIds)
  );
}


