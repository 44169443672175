import {AccessType} from '../model/checkpoint';

class Utils {
  static getFormattedDateTime(): string {
    const currentDateTime = new Date();
    return currentDateTime.getFullYear() + '-' + (currentDateTime.getMonth() + 1) + '-' +
      currentDateTime.getDate() + '-' + currentDateTime.getHours() + ':' + currentDateTime.getMinutes() + ':' +
      currentDateTime.getSeconds();
  }

  static convertTimeStampToHumanReadableDateString(timestampString: string): string {
    const parts = timestampString.split('.');
    const secondsFromYear1 = parseInt(parts[0], 10);

    // Calculate the number of seconds from 1/1/0001 to 1/1/1970
    const secondsFromYear1To1970 = (new Date('1970-01-01T00:00:00Z').getTime() - new Date('0001-01-01T00:00:00Z').getTime()) / 1000;

    // Subtract the seconds from 1/1/0001 to 1/1/1970 to get the Unix timestamp
    const unixSeconds = secondsFromYear1 - secondsFromYear1To1970;

    // Convert to a JavaScript Date object
    const date = new Date(unixSeconds * 1000);

    // Format the date as a human-readable string
    return date.toLocaleString();
  }

  static isStringArray(obj: any): obj is string[] {
    return Array.isArray(obj) && obj.every((entry: any) => typeof entry === 'string');
  }

  static isAccessType(obj: any): obj is AccessType {
    return (obj === 'private' || obj === 'organization' || obj === 'public');
  }
}

export default Utils;
