<div style="display: flex;">
  <app-dashboard></app-dashboard>
  <div class="content" *ngIf="!pageLoaded">
    <form>
      <div class="mb-3">
        <h4 class="text-dark-muted">Loading...</h4>
      </div>
    </form>
  </div>
  <div class="content" *ngIf="pageLoaded">
    <form *ngIf="!answerSubmitted">
      <div class="mb-3">
        <h4 class="text-dark-muted">Accept Invitation {{organizationName}}</h4>
      </div>
      <button type="button" class="btn btn-info" style="margin-right: 50px;" (click)="decline()">Decline</button>
      <button type="button" class="btn btn-info" (click)="accept()">Accept</button>
    </form>

    <form *ngIf="answerSubmitted && !accepted">
      <div class="mb-3">
        <h4 class="text-dark-muted">Invitation Declined</h4>
      </div>
    </form>

    <form *ngIf="answerSubmitted && accepted">
      <div class="mb-3">
        <h4 class="text-dark-muted">Invitation Accepted</h4>
      </div>
    </form>
  </div>
</div>
