import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {BehaviorSubject} from 'rxjs';
import {PythonServerService} from './python-server.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: io.Socket | undefined;

  imageData: string | undefined;

  actionProbabilities: BehaviorSubject<number[][] | undefined> = new BehaviorSubject<number[][] | undefined>(undefined);
  actions: BehaviorSubject<number[][] | undefined> = new BehaviorSubject<number[][] | undefined>(undefined);
  valueFunctionData: BehaviorSubject<Record<string, string>> = new BehaviorSubject<Record<string, string>>({});

  invalidMove: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  replayBufferUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modelUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  stepCounter = 0;
  lastTerminal = 0;

  startTime = new Date();
  stopTime = '';
  durationString = '';

  resetRequested = false;
  foundTheFlag = -1;

  timeoutIdCheckpointUploaded: any;
  constructor(private pythonServerService: PythonServerService) {
    this.pythonServerService.guiServerPublicDnsName.subscribe(url => {
      url = url?.trim();
      if (url) {
        this.connectToSocketIOServer(url);
      }
    });

    const currentURL = this.pythonServerService.guiServerPublicDnsName.getValue();
    if (currentURL) {
      this.connectToSocketIOServer(currentURL);
    }
  }

  private connectToSocketIOServer(url: string): void {
    if (this.socket) {
      this.socket.disconnect();
    }

    let servername;
    url = url.trim();

    if (url === 'localhost') {
      servername = `http://${url}:5001`;
    } else {
      servername = `https://${url}:5000`;
    }

    console.log('Connecting to ' + url);
    this.socket = this.createIOSocket(servername);
    this.socket.connect();
  }

  private createIOSocket(url: string): io.Socket {

    const socketIOOpts = {
      autoConnect: false,
      auth: {
        token: 'Bearer authorization_token_here',
        role: 'gui'
      },
      reconnection: true,
    };

    const socket = io.io(url, socketIOOpts);

    socket.on('new image', (data: any) => {
      this.imageData = 'data:image/png;base64,' + data.data;
    });
    socket.on('after connect', (data: any) => {
      console.log('Successfully established connection');
    });

    socket.on('disconnect', (data: any) => {
      console.log('Received disconnect');
    });
    socket.on('observation', (msg: any) => {
      this.parseObservation(msg);
    });
    socket.on('finished_all_uploads model', (msg: any) => {
      console.log('finished all uploads model');
      this.modelUploaded.next(true);
    });
    socket.on('finished_all_uploads replay', (msg: any) => {
      console.log('finished all uploads replay');
      this.replayBufferUploaded.next(true);
    });
    socket.on('uploaded', (msg: any) => {
      console.log('uploaded');
      console.log(msg);
      if (msg.data.includes('uploaded checkpoint.ckpt')) {
        console.log('uploaded checkpoint');
      }
    });
    socket.on('error', (msg: any) => {
      alert('error: ' + msg);
    });
    socket.on('actor_message', (msg: any) => {
      console.log('Actor message: ' + msg);
    });
    socket.on('leaner_message', (msg: any) => {
      console.log('Learner message: ' + msg);
    });

    return socket;
  }

  private parseObservation(msg: any): void {
    this.stepCounter++;
    const data = JSON.parse(msg.data);

    if (data.is_terminal) {
      console.log('is_terminal: ' + data.is_terminal);
      if (this.resetRequested) {
        this.resetRequested = false;
      } else if (this.stepCounter < this.lastTerminal + 200) {
        if (!this.stopTime) {
          this.updateTimeDiff();
        }
      }
      this.lastTerminal = this.stepCounter;
    }

    if ('is_valid_move' in data) {
      if (data.is_valid_move === 0 || data.is_valid_move === 1) {
        this.invalidMove.next(data.is_valid_move);
      } else {
        alert('is_valid_move exists but has an unexpected type ' + data.is_valid_move);
      }
    } else {
      // is_valid_move does not exist in data => just ignore it
    }

    this.valueFunctionData.next({value_function: data.value_function, predicted_reward: data.predicted_reward});

    if ('action_probabilities' in data) {
      this.actionProbabilities.next(data.action_probabilities);
    }
    if ('actions' in data) {
      this.actions.next(data.actions);
    }
  }

  public triggerCheckpointUpload(): void {
    if (this.socket) {
      this.socket?.emit('request_checkpoint');
      const maxNumSecondsToUploadCheckpoint = 2 * 60;
      this.timeoutIdCheckpointUploaded = setTimeout(() => {
        alert('Checkpoint not uploaded after ' + maxNumSecondsToUploadCheckpoint + 's');
      }, maxNumSecondsToUploadCheckpoint * 1000);
    } else {
      alert('Socket disconnected');
    }
  }

  public requestWandbLink(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.socket) {
        this.socket.emitWithAck('request_learner_metadata')
          .then(response => {
            console.log(response);
            const link: string = response.wandb_url as string;
            resolve(link);
          })
          .catch(err => {
            console.error('Error fetching learner metadata:', err);
            reject('');  // Or you can resolve with an empty string if you prefer: resolve('');
          });
      } else {
        alert('No socket initialized');
        reject('');  // Or you can resolve with an empty string if you prefer: resolve('');
      }
    });
  }

  public emitActionVector(actionVector: number[]): void {
    this.socket?.emit('action_vector', actionVector);
  }

  public emitReward(reward: number): void {
    this.socket?.emit('reward', reward);
  }

  public emitReset(): void {
    this.resetRequested = true;
    this.socket?.emit('reset');
  }

  public emitPause(pause: boolean): void {
    this.socket?.emit('pause', pause);
  }

  public emitPolicyOnly(policyOnly: boolean): void {
    this.socket?.emit('policy_only', policyOnly);
  }

  public emitManualMode(manualMode: boolean): void {
    this.socket?.emit('manual_mode', manualMode);
  }

  updateTimeDiff() {
    if (this.stopTime.length > 0) {
      return;
    }
    const stopTime = new Date();

    let timeDiff = stopTime.getTime() - this.startTime.getTime(); // This will give time difference in milliseconds

    const hours = Math.floor(timeDiff / 3600000); // Number of hours
    timeDiff = timeDiff - (hours * 3600000);

    const minutes = Math.floor(timeDiff / 60000); // Number of minutes
    timeDiff = timeDiff - (minutes * 60000);

    const seconds = Math.floor(timeDiff / 1000); // Number of seconds
    timeDiff = timeDiff - (seconds * 1000);

    const hundredths = Math.floor(timeDiff / 10); // Number of hundredths of a second

// Formatting into "HH:MM:SS:hh"
    const formattedTime = String(hours).padStart(2, '0') + ':' +
      String(minutes).padStart(2, '0') + ':' +
      String(seconds).padStart(2, '0') + ':' +
      String(hundredths).padStart(2, '0');

    this.stopTime = formattedTime;
    console.log('Solved in ' + this.stopTime);
  }
}
