<div class="grid-container" data-cy="grid-container">

  <div class="grid-area">
    {{actionsString}}
    <canvas id="actionProbabilities"></canvas>
  </div>

  <div *ngIf="imageData" class="grid-area">
    <img style="min-width:100%; min-height:100%;" [src]="imageData">
  </div>

  <div class="grid-area">
    Value Function & Rewards
    <canvas id="valueFunction"></canvas>
  </div>

  <div class="grid-area">
    <div class="grid-item">
      <button class="btn btn-info" (click)="sendReset()" title="Shortcut: 'R'">Reset</button>
    </div>
    <div class="grid-item">
      <button class="btn btn-info" [disabled]="timeoutIdCheckpointUploaded" (click)="saveCheckpoint()">Save Checkpoint</button>
    </div>
    <div class="grid-item" >
      <button *ngIf="manualMode" class="btn btn-info" (click)="setManualMode(false)">Manual Mode</button>
      <button *ngIf="!manualMode" class="btn btn-info" (click)="setManualMode(true)">AI Mode</button>
    </div>
    <div class="grid-item">
      <!--    The format '1.0-2' specifies the following:-->
      <!--    1: At least one digit before the decimal point.-->
      <!--    0-2: Anywhere between 0 and 2 digits after the decimal point.-->
      Magnitude: {{ magnitude | number:'1.0-2' }}
    </div>
    <div class="grid-item" *ngIf="trainingState === TrainingState.SESSION_ONGOING">
      <button class="btn btn-info" (click)="sendPause()">Pause</button>
    </div>
    <div class="grid-item" *ngIf="trainingState === TrainingState.PAUSE || trainingState === TrainingState.WAITING_FOR_UNPAUSE">
      <button [disabled]="trainingState === TrainingState.WAITING_FOR_UNPAUSE" class="btn btn-success" (click)="sendPause()">Unpause</button>
    </div>
    <div class="grid-item" >
      <button *ngIf="!policyOnly" class="btn btn-info" (click)="sendPolicyOnly(true)">Policy only</button>
      <button *ngIf="policyOnly" class="btn btn-success" (click)="sendPolicyOnly(false)">Resume training</button>
    </div>
    <div class="grid-item">
      <button class="btn btn-info" (click)="sendReward(1)" title="Shortcut: arrow up">Positive</button>
    </div>
    <div class="grid-item">
      <button class="btn btn-info" (click)="sendReward(-1)" title="Shortcut: arrow down">Negative</button>
    </div>
    <div class="grid-item" style="width: 100%;">
      <button class="btn btn-outline-danger" (click)="stopCloudServer()" data-cy="btn-Stop Training Session">
        Stop Training Session</button>
    </div>
  </div>


  <div class="overlay"
       *ngIf="!closeOverlayWin && (trainingState === TrainingState.PAUSE || trainingState === TrainingState.WAITING_FOR_UNPAUSE)">
    <div class="overlay-content">
      <p>The training session is currently paused.</p>
      <button class="btn btn-info" (click)="sendPause()"
              [disabled]="trainingState === TrainingState.WAITING_FOR_UNPAUSE"
              data-cy="btn-Continue Training Session">Start/ Continue Training Session
      </button>
    </div>
  </div>

  <div class="overlay" *ngIf="!closeOverlayTimingWin && stopTime.length > 0">
    <div class="overlay-content">
      <h2> Congratulations!!! </h2>
      <br>
      <p>Captured the flag in </p>
      <br>
      <p>{{stopTime}}</p>
      <button class="btn btn-info" (click)=closeOverlayTiming()>Continue</button>
    </div>
  </div>

  <div class="overlay" *ngIf="warningRewardOnPolicyOnly == 'shown'">
    <div class="overlay-content">
      <p>Rewards should not be given when running in policy-only mode, since they will not be send to the server.</p>
      <button class="btn btn-info" (click)="closeWarningRewardOnPolicyOnly()">OK</button>
    </div>
  </div>


</div>
