import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DataService} from '../../shared/data.service';
import {AuthService} from '../../shared/auth.service';
import { take } from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Organization} from '../../model/organization';



@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.css']
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {
  organizationName = '';
  pageLoaded = false;
  accepted = false;
  answerSubmitted = false;
  organizationId = '';

  subscriptions: Subscription[] = [];


  constructor(private route: ActivatedRoute,
              private dataService: DataService,
              private authService: AuthService) {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        // Check if userProfile is linked to organization
        this.route.queryParams.pipe(take(1)).subscribe(params => {
          const organizationId = params.organizationId;
          const email = params.email;

          if (authService.getEmail() !== email) {
            alert('This invitation email is not meant for the account you logged in with. Sign out and sign back in with the ' +
              'account associated to ' + email + '. Next, click on the invitation link again.');
            return;
          }

          console.log(params);
          console.log(organizationId);
          if (organizationId) {
            // Check if user is invited for this organization
            this.dataService.checkUserInvitedToOrganization(organizationId).subscribe(isInvited => {
              if (isInvited) {
                this.dataService.getOrganization(organizationId).subscribe(data => {
                  const organization: Organization = data.payload.data() as Organization;
                  this.organizationName = organization.name;
                  this.organizationId = organization.id;
                  this.pageLoaded = true;
                }, error => {
                  alert(error.message);
                });
              } else {
                alert('The user has not been invited for this organization. You may be logged in with another account than the one you ' +
                  'received the invite for. If so: (1) log in with the correct account, (2) click again on the link in the invitation email.' +
                  ' Otherwise, contact us.');
              }
            });
          } else {
            alert('Invalid invitation link');
          }
        });
      }
    }));
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  decline() {
    this.accepted = false;
    this.answerSubmitted = true;
  }

  accept() {
    this.dataService.linkUserToOrganization(this.organizationId).subscribe(response => {},
      error => {
        alert(error.message);
      });
    this.accepted = true;
    this.answerSubmitted = true;
  }
}
