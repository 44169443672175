import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Keypad} from '../../model/keypad';
import {DataService} from '../../shared/data.service';
import {AuthService} from '../../shared/auth.service';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../shared/configuration.service';
import {MatSnackBar} from '@angular/material/snack-bar';
declare var bootstrap: any;

@Component({
  selector: 'app-keypads',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.css']
})
export class KeypadComponent implements OnInit, OnDestroy {

  id = '';
  name = '';
  description = '';
  voiceRecordButton = false;
  keys: string[] = [''];
  texts: string[] = [''];

  keypadObj: Keypad = {keys: [], texts: [], description: '', id: '', name: '', voiceRecordButton: false, uid: ''};

  keypads: Keypad[] = [];

  isAddNewKeypad = true;

  subscriptions: Subscription[] = [];


  constructor(private dataService: DataService,
              private authService: AuthService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        this.getAllKeypads();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  changeTab(): void {
    this.isAddNewKeypad = !this.isAddNewKeypad;
  }

  getAllKeypads() {
    this.subscriptions.push(this.dataService.getUserKeypadObservable().subscribe(changeDetected => {
      this.dataService.getUserKeypads().subscribe(res => {
        this.keypads = res;
      }, err => {
        alert(err.message);
      });
    }));
  }

  resetForm(): void {
    this.id = '';
    this.name = '';
    this.description = '';
    this.voiceRecordButton = false;
    this.keys.splice(0);
    this.texts.splice(0);
    this.addKey();
  }

  addKeypad() {
    if (this.name === '') {
      alert('The name field is required');
      return;
    }

    this.keypadObj.id = '';
    this.keypadObj.name = this.name;
    this.keypadObj.description = this.description;
    this.keypadObj.voiceRecordButton = this.voiceRecordButton;
    const keysTemp: string[] = [];
    const textsTemp: string[] = [];
    for (let i = 0; i < this.keys.length; i++) {
      if (this.keys[i].length > 0 && this.texts[i].length > 0) {
        keysTemp.push(this.keys[i]);
        textsTemp.push(this.texts[i]);
      }
    }
    this.keypadObj.keys = keysTemp;
    this.keypadObj.texts = textsTemp;

    this.dataService.addKeypad(this.keypadObj).subscribe(
      response => {
        this.snackBar.open('Keypad has been added', 'Close', {
          duration: 8000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['my-snackbar-class']
        });
      },
      error => {
        this.snackBar.open('Failed to add keypad', 'Close', {
          duration: 8000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['my-snackbar-class']
        });
      }
    );

    this.resetForm();
  }

  deleteKeypad(keypad: Keypad) {
    if (window.confirm('Are you sure you want to delete ' + keypad.name + ' ?')) {
      this.dataService.deleteKeypad(keypad).subscribe(response => {});
    }
  }

  addKey(): void {
    this.keys = [...this.keys, ''];
    this.texts = [...this.texts, ''];
  }

  onKeydown(event: KeyboardEvent, index: number): void {
    const key: string = event.key;
    event.preventDefault();
    this.keys[index] = event.key;
  }

  removeKey(index: number): void {
    this.keys.splice(index, 1);
    this.texts.splice(index, 1);
  }

  updateText(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    this.texts[index] = target.value;
  }
}
