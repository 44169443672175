<div style="display: flex;">
  <app-dashboard></app-dashboard>
  <div class="content">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isAddNewKeypad}" (click)="changeTab()">Add New Keypad</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': !isAddNewKeypad}" (click)="changeTab()">List of Keypads</a>
      </li>
    </ul>

    <form *ngIf="isAddNewKeypad">
      <div class="mb-3">
        <h4 class="text-dark-muted">Add New Keypad</h4>
      </div>
      <div class="mb-3">

        <label class="form-label">Name</label>
        <input type="email" class="form-control" name="email" [(ngModel)]="name">
      </div>
      <div class="mb-3">

        <label class="form-label">Description</label>
        <textarea type="email" class="form-control" name="email" [(ngModel)]="description"></textarea>
      </div>

      <div class="mb-3">

        <label class="form-label">Record voice button</label>
        <br>

        <div class="form-label btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [(ngModel)]="voiceRecordButton" [value]="false">
          <label class="btn btn-outline-primary" for="btnradio1">Off</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [(ngModel)]="voiceRecordButton" [value]="true">
          <label class="btn btn-outline-primary" for="btnradio2">On</label>
        </div>


      </div>

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Key</th>
          <th scope="col">Instruction</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let k of keys; index as i">
          <th scope="row"><input name="first" [value]="keys[i]" (keydown)="onKeydown($event, i)" placeholder="Click here & press any key" />
          </th>
          <td><input name="second" [value]="texts[i]" (input)="updateText($event, i)" placeholder="E.g. 'Go left'" /></td>
          <td><button type="button" class="btn btn-info" (click)="removeKey(i)" [disabled]="keys.length == 1">Remove</button></td>
        </tr>
        <tr>
          <th scope="row"><button type="button" class="btn btn-info" (click)="addKey()">Add key</button></th>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <button type="button" class="btn btn-info" (click)="addKeypad()">Add Keypad</button>
    </form>

    <form *ngIf="!isAddNewKeypad">
      <div class="mb-3">
        <h4 class="text-dark-muted">List of Keypads</h4>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Description</th>
          <th scope="col">Key Count</th>
          <th scope="col">Voice enabled</th>
          <th scope="col"> Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let keypad of keypads; index as i">
          <th scope="row">{{keypad.name}}</th>
          <td>{{keypad.description}}</td>
          <td>{{keypad.keys.length}}</td>
          <td>{{keypad.voiceRecordButton ? 'yes' : 'no'}}</td>
          <td><button class="btn btn-outline-danger" (click)="deleteKeypad(keypad)">Delete</button></td>
        </tr>
        </tbody>
      </table>

    </form>
  </div>
</div>
