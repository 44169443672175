<div class="container-fluid">
  <div class="row mt-5">
    <div class="col"></div>
    <div class="col-4 min-width">
      <div class="card shadow">
        <div class="card-body">
          <!-- Confirm Verification Section -->
          <div *ngIf="mode === 'verifyEmail'">
            <form>
              <div class="mb-3">
                <h4 class="text-dark-muted">Welcome</h4>
              </div>
              <div *ngIf="!verified">
                Verifying link...
              </div>
              <div *ngIf="verified">
                <div class="mb-3">
                  <a>Your email has been verified</a>
                </div>
                <div class="mb-3">
                  <a routerLink="/training" style="text-decoration: none;">Go to TurboTeach</a>
                </div>
              </div>
            </form>
          </div>

          <!-- Reset Password Section -->
          <div *ngIf="mode === 'resetPassword' && resetPasswordForm">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordSubmit()">
              <div class="mb-3">
                <h4 class="text-dark-muted">Reset Password</h4>
              </div>

              <div class="mb-3">
                <label class="form-label" for="newPassword">New Password</label>
                <input type="password" id="newPassword" class="form-control" formControlName="newPassword">
                <div *ngIf="resetPasswordForm.controls.newPassword.errors && resetPasswordForm.controls.newPassword.touched">
                  <!-- You can add error messages for newPassword field -->
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label" for="confirmPassword">Confirm New Password</label>
                <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword">
                <div *ngIf="resetPasswordForm.controls.confirmPassword.errors && resetPasswordForm.controls.confirmPassword.touched">
                  <div *ngIf="resetPasswordForm.controls.confirmPassword.errors?.mustMatch">Passwords must match.</div>
                  <!-- You can add other error messages for confirmPassword field -->
                </div>
              </div>

              <button type="submit" class="btn btn-info">Reset Password</button>
              <div class="row mt-3">
                <div class="col-6">
                  <a routerLink="/login" style="text-decoration: none;">Back to Login</a>
                </div>
                <div class="col-md-6">
                  <!-- You can keep other links if needed -->
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
