import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Keypad} from '../model/keypad';
import {AuthService} from './auth.service';
import {isOrganization, Organization} from '../model/organization';
import {Checkpoint, isCheckpoint} from '../model/checkpoint';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // @ts-ignore
  userCollection: AngularFirestoreDocument<unknown>;
  constructor(private afs: AngularFirestore, private authService: AuthService,
              private functions: AngularFireFunctions) {
    this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        this.userCollection = this.afs.collection('users').doc(this.authService.getUserId());
      }
    });
  }

  ///////////////////////////////////////////////////////////
  // Organization Management
  ///////////////////////////////////////////////////////////

  addOrganization(organization: Organization) {
    if (!isOrganization(organization)) {
      alert('not a valid organization');
      console.log(organization);
    }
    const createOrganization = this.functions.httpsCallable('createOrganization');
    return createOrganization({organization});
  }

  updateOrganization(organization: Organization) {
    const updateOrganization = this.functions.httpsCallable('updateOrganization');
    return updateOrganization({organization});
  }

  getOrganization(organizationId: string) {
    return this.afs.collection('organizations').doc(organizationId).snapshotChanges();
  }

  checkUserInvitedToOrganization(organizationId: string) {
    const checkUserInvitedToOrganization = this.functions.httpsCallable('checkUserInvitedToOrganization');
    return checkUserInvitedToOrganization({organizationId});
  }

  removeTeamMember(teamMemberEmail: string) {
    const removeTeamMember = this.functions.httpsCallable('removeTeamMember');
    return removeTeamMember({teamMemberEmail});
  }

  sendInvitation(recipientEmail: string, organizationId: string) {
    const sendInvitationFunction = this.functions.httpsCallable('sendInvitation');
    return sendInvitationFunction({ recipientEmail, organizationId });
  }

  ///////////////////////////////////////////////////////////
  // User Profile Management
  ///////////////////////////////////////////////////////////

  createUserProfile(email: string) {
    const createUserProfile = this.functions.httpsCallable('createUserProfile');
    return createUserProfile({email});
  }

  userProfileSnapshotChanges() {
    return this.userCollection.snapshotChanges();
  }

  linkUserToOrganization(organizationId: string) {
    const linkUserToOrganization = this.functions.httpsCallable('linkUserToOrganization');
    return linkUserToOrganization({organizationId});
  }

  updateUserName(name: string) {
    const updateUserName = this.functions.httpsCallable('updateUserName');
    return updateUserName({name});
  }

  ///////////////////////////////////////////////////////////
  // Keypad Management
  ///////////////////////////////////////////////////////////

  addKeypad(keypad: Keypad) {
    const addKeypad = this.functions.httpsCallable('addKeypad');
    return addKeypad({keypad});
  }

  getUserKeypads() {
    const getUserKeypads = this.functions.httpsCallable('getUserKeypads');
    return getUserKeypads({});
  }

  getUserKeypadObservable() {
    return this.userCollection.valueChanges(['keypadIds']);
  }

  deleteKeypad(keypad: Keypad) {
    const deleteKeypad = this.functions.httpsCallable('deleteKeypad');
    return deleteKeypad({keypadId: keypad.id});
  }

  ///////////////////////////////////////////////////////////
  // Checkpoint management
  ///////////////////////////////////////////////////////////

  createCheckpointFromExistingCheckpoint(newCheckpoint: Checkpoint, existingCheckpointId: string) {
    if (!isCheckpoint(newCheckpoint)) {
      alert('not a valid checkpoint');
      console.log(newCheckpoint);
      throw new Error('not a valid checkpoint');
    }
    const createCheckpointFromExistingCheckpoint = this.functions.httpsCallable('createCheckpointFromExistingCheckpoint');
    return createCheckpointFromExistingCheckpoint({newCheckpoint, existingCheckpointId});
  }

  createCheckpointFromScratch(checkpoint: Checkpoint) {
    if (!isCheckpoint(checkpoint)) {
      alert('not a valid checkpoint');
      console.log(checkpoint);
      throw new Error('not a valid checkpoint');
    }
    const createCheckpointFromScratch = this.functions.httpsCallable('createCheckpointFromScratch');
    return createCheckpointFromScratch(checkpoint);
  }

  managePauseCheckpoint(checkpointId: string, pause: boolean) {
    const managePauseCheckpoint = this.functions.httpsCallable('managePauseCheckpoint');
    return managePauseCheckpoint({checkpointId, pause});
  }

  updateStopTrainingTimeCheckpoint(checkpointId: string) {
    const updateStopTrainingTimeCheckpoint = this.functions.httpsCallable('updateStopTrainingTimeCheckpoint');
    return updateStopTrainingTimeCheckpoint({id: checkpointId});
  }

  getAllCheckpointsOrganization() {
    const getAllCheckpointsOrganization = this.functions.httpsCallable('getAllCheckpointsOrganization');
    return getAllCheckpointsOrganization({});
  }

  deleteCheckpoint(checkpointId: string) {
    const deleteCheckpoint = this.functions.httpsCallable('deleteCheckpoint');
    return deleteCheckpoint({checkpointId});
  }
}
