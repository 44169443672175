import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';
import User = firebase.User;
import {RerouteService} from './reroute.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authSubscription: Subscription | null = null;
  private user: User | null = null;
  public authState: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>('unknown');

  constructor(private fireauth: AngularFireAuth,
              private router: Router,
              private rerouteService: RerouteService) {
    this.attemptToRetrieveUserId();
  }

  private attemptToRetrieveUserId(): void {
    this.authSubscription = this.fireauth.authState.subscribe((user) => {
      this.user = user;
      if (user) {
        if (user.emailVerified) {
          this.authState.next('signedInAndVerified');
        } else {
          this.authState.next('signedInAndNotVerified');
        }
      } else {
        // No user is signed in.
        console.log('No user is signed in.');
        this.authState.next('signedOut');
      }
      this.authSubscription?.unsubscribe();
    });
  }

  // Login method
  login(email: string, password: string): void {
    this.fireauth.signInWithEmailAndPassword(email, password).then(res => {
      localStorage.setItem('token', 'true');

      if (res.user?.emailVerified) {
        this.user = res.user;
        this.authState.next('signedInAndVerified');
        this.rerouteService.navigateToLinkAfterLogin();
      } else {
        this.router.navigate(['/verify-email']);
      }
    }, err => {
      alert(err.message);
      this.router.navigate(['/login']);
    });
  }

  // register method
  register(email: string, password: string): void {
    this.fireauth.createUserWithEmailAndPassword(email, password).then(res => {
      const user: firebase.User = res.user as firebase.User;
      this.sendVerificationEmail(user);
    }, err => {
      alert(err.message);
      this.router.navigate(['/register']);
    });
  }

  // sign out
  logout(): void {
    this.fireauth.signOut().then(() => {
      this.router.navigate(['/login']);
      localStorage.removeItem('token');
      this.authState.next('signedOut');
    }, err => {
      alert(err.message);
    });
  }

  // forgot password
  forgotPassword(email: string): void {
    this.fireauth.sendPasswordResetEmail(email).then(() => {
      this.router.navigate(['/verify-email']);
    }, err => {
      alert(err.message);
    });
  }

  // email verification
  sendVerificationEmail(user: firebase.User): void {
    user.sendEmailVerification().then((res: any) => {
      this.router.navigate(['/verify-email']);
    }, (err: any) => {
      alert('Something went wrong. Failed to send email');
    });
  }

  getEmail(): string {
    const user: firebase.User = this.user as firebase.User;
    // @ts-ignore
    return user.email;
  }

  getUserId(): string {
    const user: firebase.User = this.user as firebase.User;
    if (!user) {
      alert('no user defined');
    }
    return user.uid;
  }
}

export type AuthState = 'unknown' | 'signedInAndVerified' | 'signedInAndNotVerified' | 'signedOut';

