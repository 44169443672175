<div class="container-fluid">
  <div class="row mt-5">
    <div class="col"></div>
    <div class="col-4 min-width">
      <div class="card shadow">
        <div class="card-body">
          <form (keydown)="onKeyDown($event)">
            <form>
              <div class="mb-3">
                <h4 class="text-dark-muted">Register</h4>
              </div>
              <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="email">
              </div>
              <div class="mb-3">
                <label class="form-label">Create Password</label>
                <input type="password" class="form-control" name="password" [(ngModel)]="password">
              </div>
              <div class="mb-3">
                <label class="form-label">Repeat Password</label>
                <input type="password" class="form-control" name="passwordRepeat" [(ngModel)]="passwordRepeat">
              </div>
              <button type="button" class="btn btn-info" (click)="register()">Register</button>
              <div class="row">
                <div class="col-6">
                  <a routerLink="/login" style="text-decoration: none;">Have an account ? <span class="text-danger">Login</span> </a>
                </div>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
