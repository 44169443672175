import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Chart, ChartConfiguration} from 'chart.js/auto';
import {SocketService} from '../../../shared/socket.service';
import {Subscription} from 'rxjs';
import {ConfigurationService, TrainingState} from '../../../shared/configuration.service';
import {Robot} from '../../../model/robot';
import {PythonServerService} from '../../../shared/python-server.service';


@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit, AfterViewInit, OnDestroy {

  actionsString = '';

  guiServerHost: string | undefined = undefined;

  subscriptions: Subscription[] = [];

  private sentRewardTemp = 0;

  private chartValueFunction: any;
  private chartActionProbabilities: any;

  magnitude = 1;
  keyStates: any = {};
  manualMode = false;

  poseVector: number[] = [0, 0, 0, 0, 0, 0];
  lastGripperState = 0;
  gripperState = 0;

  private colors: string[] = [
    '#06D6A0', // Greenish
    '#118AB2', // Blueish
    '#FFD166', // Yellowish
    '#073B4C', // Dark Blue
    '#EF476F', // Reddish
    '#8C43FF', // Purple
    '#FFA3D7', // Pink
    '#FFE156', // Light Yellow
    '#17BEA0', // Cyan
    '#4A1C4D'  // Dark Purple
  ];

  private dataValueFunction = {
    labels: Array.from(Array(100).keys()),
    datasets: [
      {
        data: Array(100).fill(0),
        label: 'Value Function',
        borderColor: this.colors[0],
        tension: 0,
        fill: false,
        pointRadius: 0
      },
      {
        data: Array(100).fill(0),
        label: 'Sent rewards',
        borderColor: this.colors[1],
        tension: 0,
        fill: false,
        pointRadius: 0
      },
      {
        data: Array(100).fill(0),
        label: 'Predicted rewards',
        borderColor: this.colors[2],
        tension: 0,
        fill: false,
        pointRadius: 0
      },
      {
        data: Array(100).fill(0),
        label: 'TargetOutOfReach',
        borderColor: this.colors[3],
        tension: 0,
        fill: false,
        pointRadius: 0
      },
    ]
  };
  private dataActionProbabilities = {
    labels: Array.from(Array(100).keys()),
    datasets: [{
      data: Array(100).fill(0),
      label: '',
      borderColor: this.colors[0],
      tension: 0,
      fill: false,
      pointRadius: 0
    }]
  };

  private counterValueFunction = 0;
  private counterActionProbabilities = 0;

  closeOverlayWin = false;
  closeOverlayTimingWin = false;

  policyOnly = false;
  warningRewardOnPolicyOnly: 'hidden' | 'shown' = 'hidden';

  public readonly TrainingState = TrainingState;

  constructor(private socketService: SocketService,
              private configurationService: ConfigurationService,
              private pythonServerService: PythonServerService) {
  }

  saveCheckpoint() {
    this.socketService.triggerCheckpointUpload();
  }

  public get timeoutIdCheckpointUploaded(): any {
    return this.socketService.timeoutIdCheckpointUploaded;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.pythonServerService.guiServerPublicDnsName.subscribe(url => {
        this.guiServerHost = url;
      })
    );

    this.subscriptions.push(this.socketService.actions.subscribe(dataArray => {
      if (dataArray) {
        this.actionsString = 'Continuous Actions';
        this.updateActionChart(dataArray);
      }
    }));
    this.subscriptions.push(this.socketService.actionProbabilities.subscribe(dataArray => {
      if (dataArray) {
        this.actionsString = 'Action Probabilities';
        this.updateActionChart(dataArray);
      }
    }));
    this.subscriptions.push(this.socketService.valueFunctionData.subscribe(data => {
      if (data) {
        const trainingState: TrainingState = this.configurationService.trainingState.value;

        switch (trainingState) {
          case TrainingState.NO_TRAINING:
          case TrainingState.SESSION_ONGOING:
          case TrainingState.PAUSE:
            break;
          case TrainingState.WAITING_FOR_UNPAUSE:
            this.configurationService.trainingState.next(TrainingState.SESSION_ONGOING);
            break;
          case TrainingState.WAITING_FOR_CLOUD_SERVER:
            this.sendPause();
            if (this.configurationService.isElectron()) {
              this.configurationService.getIpcRenderer().send('show-notification');
            }
            break;
          default:
            alert('Unexpected training state: ' + trainingState);
        }
        this.updateValueFunctionChart(data);
      }
    }));

    setInterval(() => {
      const actionVector = [0, 0, 0, 0, 0, 0];

      for (const key in this.keyStates) {
        if (this.keyStates[key].pressed || !this.keyStates[key].processed) {
          switch (key) {
            case 'w':
              actionVector[0] = -1;
              break;
            case 's':
              actionVector[0] = 1;
              break;
            case 'a':
              actionVector[1] = -1;
              break;
            case 'd':
              actionVector[1] = 1;
              break;
            case 'g':
              actionVector[2] = -1;
              break;
            case 't':
              actionVector[2] = 1;
              break;
            case 'h':
              actionVector[3] = -1;
              break;
            case 'y':
              actionVector[3] = 1;
              break;
            case 'j':
              actionVector[4] = -1;
              break;
            case 'u':
              actionVector[4] = 1;
              break;
            case 'k':
              actionVector[5] = -1;
              break;
            case 'i':
              actionVector[5] = 1;
              break;
            case 'l':
              this.gripperState = -1;
              break;
            case 'o':
              this.gripperState = 1;
              break;
          }
          this.keyStates[key].processed = true;
        }
      }

      let actionVectorUpdated = false;
      for (const item of actionVector) {
        if (item !== 0) {
          actionVectorUpdated = true;
        }
      }

      if (actionVectorUpdated) {
        for (let i = 0; i < actionVector.length; i++) {
          actionVector[i] *= this.magnitude;
        }
        this.poseVector = actionVector;
      }

      this.updateActions();
    }, 100);
  }

  updateActions() {
    let poseVectorUpdated = false;
    for (const item of this.poseVector) {
      if (item !== 0) {
        poseVectorUpdated = true;
      }
    }
    if (poseVectorUpdated || this.gripperState !== this.lastGripperState) {
      const actionVector = [...this.poseVector];
      actionVector.push(this.gripperState);
      console.log(actionVector);
      this.socketService.emitActionVector(actionVector);
      this.lastGripperState = this.gripperState;
      this.poseVector = [0, 0, 0, 0, 0, 0];
    }
  }

  ngAfterViewInit() {
    const ctxValueFunction = document.getElementById('valueFunction') as HTMLCanvasElement;
    this.chartValueFunction = new Chart(ctxValueFunction, {
      type: 'line',
      data: this.dataValueFunction,
      options: {
        plugins: {
          legend: {
            display: true
          }
        },
        responsive: true,
        animation: false,
        scales: {
          x: {
            display: true
          },
          y: {
            display: true,
          }
        }
      }
    } as ChartConfiguration<'line'>);

    const ctxActionProbabilities = document.getElementById('actionProbabilities') as HTMLCanvasElement;
    this.chartActionProbabilities = new Chart(ctxActionProbabilities, {
      type: 'line',
      data: this.dataActionProbabilities,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true
          }
        },
        animation: false,
        scales: {
          x: {
            display: true
          },
          y: {
            display: true,
          }
        }
      }
    } as ChartConfiguration<'line'>);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  public get imageData() {
    return this.socketService.imageData;
  }

  getLabel(i: number): string {
    let label = '';
    if (this.currentRobot.actionsDiscrete.length > 0) {
      label = this.currentRobot.actionsDiscrete[i];
      this.actionsString = 'Action Probabilities';
    } else if (this.currentRobot.actionsContinuous.length > 0) {
      label = this.currentRobot.actionsContinuous[i];
      this.actionsString = 'Continuous Actions';
    } else {
      alert('Did not find any way to fill legend of actions graph');
    }
    return label;
  }

  updateValueFunctionChart(data: Record<string, string>) {
    const valueToOverwrite = this.counterValueFunction % 100;
    const valueToNaN = (this.counterValueFunction + 1) % 100;

    // Value function
    this.dataValueFunction.datasets[0].data[valueToOverwrite] = Number(data.value_function);
    this.dataValueFunction.datasets[0].data[valueToNaN] = NaN;

    // Sent rewards
    this.dataValueFunction.datasets[1].data[valueToOverwrite] = this.sentRewardTemp;
    this.dataValueFunction.datasets[1].data[valueToNaN] = NaN;
    this.sentRewardTemp = 0;

    // Predicted rewards
    this.dataValueFunction.datasets[2].data[valueToOverwrite] = Number(data.predicted_reward);
    this.dataValueFunction.datasets[2].data[valueToNaN] = NaN;

    // Target reach error
    const targetReachError = this.socketService.invalidMove.value === undefined ? undefined : 1 - this.socketService.invalidMove.value;
    this.dataValueFunction.datasets[3].data[valueToOverwrite] = targetReachError;
    this.dataValueFunction.datasets[3].data[valueToNaN] = NaN;

    // Update
    this.chartValueFunction?.update();

    // Counter
    this.counterValueFunction++;
  }

  updateActionChart(data: number[][]) {
    const dataArray: number[] = data[0];
    for (let i = 0; i < dataArray.length; i++) {
      if (this.dataActionProbabilities.datasets.length <= i) {
        this.dataActionProbabilities.datasets.push({
          data: Array(100).fill(0),
          label: this.getLabel(i),
          borderColor: this.colors[i % this.colors.length],
          tension: 0,
          fill: false,
          pointRadius: 0
        });
      }
      if (this.dataActionProbabilities.datasets[i].label === '') {
        this.dataActionProbabilities.datasets[i].label = this.getLabel(i);
      }
      this.dataActionProbabilities.datasets[i].data[this.counterActionProbabilities % 100] = Number(dataArray[i]);
      this.dataActionProbabilities.datasets[i].data[(this.counterActionProbabilities + 1) % 100] = NaN;
    }

    this.chartActionProbabilities?.update();
    this.counterActionProbabilities++;
  }

  async sendReward(value: number) {
    // Rewards should not be provided if we are running policy-only mode,
    // since they would not be sent to the learner.
    if (this.policyOnly) {
      this.warningRewardOnPolicyOnly = 'shown';
      return;
    }

    this.sentRewardTemp += value;
    this.socketService.emitReward(value);
  }

  async sendReset() {
    this.socketService.emitReset();
  }

  async sendPause() {
    this.socketService.startTime = new Date();
    const trainingState: TrainingState = this.configurationService.trainingState.value;
    switch (trainingState) {
      case TrainingState.NO_TRAINING:
      case TrainingState.WAITING_FOR_UNPAUSE:
        alert('Unexpected sendPause() request while trainingState: ' + trainingState);
        return;
      case TrainingState.PAUSE:
        this.configurationService.trainingState.next(TrainingState.WAITING_FOR_UNPAUSE);
        break;
      case TrainingState.WAITING_FOR_CLOUD_SERVER:
      case TrainingState.SESSION_ONGOING:
        if (!this.manualMode) {
          this.configurationService.trainingState.next(TrainingState.PAUSE);
        } else {
          console.log('Impossible to pause actor in manual mode');
        }
        break;
      default:
        alert('Unknown TrainingState: ' + trainingState);
    }

    this.socketService.emitPause(this.configurationService.trainingState.value === TrainingState.PAUSE);
  }

  async sendPolicyOnly(policyOnly: boolean) {
    this.policyOnly = policyOnly;
    this.socketService.emitPolicyOnly(this.policyOnly);
  }

  @HostListener('window:keydown', ['$event'])
  sendRewardViaKey(event: KeyboardEvent) {
    if (this.trainingState !== TrainingState.SESSION_ONGOING) {
      return;
    }

    const diffMagnitude = 1.25;
    if (event.key === 'ArrowLeft') {
      this.magnitude /= diffMagnitude;
    } else if (event.key === 'ArrowRight') {
      this.magnitude *= diffMagnitude;
    } else if (event.key === 'ArrowUp') {
      this.sendReward(1).then(r => {});
    } else if (event.key === 'ArrowDown') {
      this.sendReward(-1).then(r => {});
    } else if (event.key === 'r') {
      this.sendReset().then(r => {});
    } else if (event.key === 'p') {
      this.sendPause().then(r => {});
    } else if (event.key === 'm') {
      this.setManualMode(!this.manualMode);
    }
    // If the key is not already pressed, or if it has been released and not processed yet
    if (!this.keyStates[event.key] || this.keyStates[event.key].processed) {
      this.keyStates[event.key] = {
        pressed: true,
        processed: false
      };
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUps(event: KeyboardEvent) {
    if (this.keyStates[event.key]) {
      this.keyStates[event.key].pressed = false;
    }
  }

  stopCloudServer(): void {
    this.pythonServerService.stopCloudServer();
    this.configurationService.trainingState.next(TrainingState.NO_TRAINING);
  }

  closeOverlayTiming() {
    this.closeOverlayTimingWin = true;
  }

  closeWarningRewardOnPolicyOnly() {
    this.warningRewardOnPolicyOnly = 'hidden';
  }

  public get currentRobot(): Robot {
    return this.configurationService.currentRobot;
  }

  public get trainingState(): TrainingState {
    return this.configurationService.trainingState.value;
  }

  public get stopTime(): string {
    return this.socketService.stopTime;
  }

  setManualMode(manualMode: boolean): void {
    if (!this.manualMode && manualMode) {
      // If the user specifies the gripper stand (1 or -1), we will execute it, also when the gripper is already
      // in that position. As long as the user does not specify the position, we will keep sending a zero with each
      // actionVector, which is interpreted by the backend as just keeping the current state.
      this.lastGripperState = 0;
    }
    this.manualMode = manualMode;
    this.socketService.emitManualMode(manualMode);
  }
}
