import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {DataService} from '../../shared/data.service';
import {AuthService} from '../../shared/auth.service';
import {RerouteService} from '../../shared/reroute.service';

@Component({
  selector: 'app-unified-actions',
  templateUrl: './unified-actions.component.html',
  styleUrls: ['./unified-actions.component.css']
})
export class UnifiedActionsComponent implements OnInit, OnDestroy {
  mode: string | null = null;
  verified = false;
  resetPasswordForm: FormGroup | undefined;
  oobCode: string | undefined;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private authService: AuthService,
    private rerouteService: RerouteService,
  ) { }



  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.mode = params.mode;
      this.oobCode = params.oobCode;
      const emailForWhichLinkIsValid = params.email;

      if (!this.oobCode) {
          alert('oobCode is empty');
      } else if (this.mode === 'verifyEmail') {
        // Handle email verification
        this.confirmEmailVerification(this.oobCode, emailForWhichLinkIsValid);
      } else if (this.mode === 'resetPassword') {
        // Handle reset password
        this.resetPasswordForm = this.fb.group({
          newPassword: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', Validators.required]
        }, {
          validator: this.mustMatch('newPassword', 'confirmPassword')
        });
      }
    });
  }

  confirmEmailVerification(oobCode: string, emailForWhichLinkIsValid: string) {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
        if (authState === 'unknown') {
            // Wait for next call when it's known
        } else if (authState === 'signedInAndVerified') {
            // Check if userProfile is linked to organization
            if (emailForWhichLinkIsValid === this.authService.getEmail()) {
                alert('Your account has been verified already.');
                this.rerouteService.navigateToLinkAfterLogin();
            } else {
                alert('This link is invalid for the account with which you signed in. 1) Sign out and 2) click the ' +
                    'verification link again.');
                this.router.navigate(['logout']);
            }
        } else if (authState === 'signedInAndNotVerified' || authState === 'signedOut') {
            // Check if userProfile is linked to organization

            if (authState === 'signedInAndNotVerified' && this.authService.getEmail() !== emailForWhichLinkIsValid) {
                alert('This verification email is not meant for the account you logged in with. Sign out and sign back in with the ' +
                    'account associated to ' + emailForWhichLinkIsValid + '. Next, click on the verification link again.');
                return;
            }

            // Kill subscription immediately, to avoid that this function is called twice
            this.ngOnDestroy();

            // Verify if the action code is valid.
            this.afAuth.applyActionCode(oobCode)
                .then(() => {
                    if (this.authService.authState.value === 'signedInAndNotVerified') {
                        this.authService.authState.next('signedInAndVerified');
                    }
                    this.dataService.createUserProfile(emailForWhichLinkIsValid).subscribe(res => {
                            console.log('UserProfile created');
                            this.verified = true;
                        },
                        error => {
                            alert(error.message);
                        });
                })
                .catch(error => {
                    // Code is invalid or expired
                    alert(error.message);
                });
        }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onResetPasswordSubmit() {
    if (this.resetPasswordForm && this.resetPasswordForm.valid) {
      const newPassword = this.resetPasswordForm.value.newPassword;
      if (!this.oobCode) {
        console.error('oobCode undefined');
        return;
      }
      this.afAuth.confirmPasswordReset(this.oobCode, newPassword).then(() => {
        alert('Password reset successful!');
        this.router.navigate(['/login']); // navigate to login or some other page after successful reset
      }).catch(err => {
        alert(err.message);
      });
    }
  }
}
