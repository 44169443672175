import Utils from '../shared/utils';

export interface Checkpoint {
  id: string;
  modelSize: string;
  startedFromCheckpointId: string;
  uuid: string;
  brainName: string;
  startTrainingSessionTime: string;
  stopTrainingSessionTime: string;
  ownerId: string;
  ownerName: string;
  organizationId: string;
  accessType: AccessType;
  paused: string[];
  unpaused: string[];
  robot: string;
  wandbLink: string;
}

export function printCheckpoint(checkpoint: Checkpoint) {
  let s = '';
  s += Utils.convertTimeStampToHumanReadableDateString(checkpoint.stopTrainingSessionTime);
  s += checkpoint.brainName + '  ';
  s += checkpoint.ownerName + '  ';
  s += checkpoint.modelSize + '  ';
  s += checkpoint.robot + '  ';
  return s;
}

export function isCheckpoint(obj: any): obj is Checkpoint {
  return (
    typeof obj === 'object' &&
    'id' in obj && typeof obj.id === 'string' &&
    'modelSize' in obj && typeof obj.modelSize === 'string' &&
    'startedFromCheckpointId' in obj && typeof obj.startedFromCheckpointId === 'string' &&
    'uuid' in obj && typeof obj.uuid === 'string' &&
    'brainName' in obj && typeof obj.brainName === 'string' &&
    'startTrainingSessionTime' in obj && typeof obj.startTrainingSessionTime === 'string' &&
    'stopTrainingSessionTime' in obj && typeof obj.stopTrainingSessionTime === 'string' &&
    'ownerId' in obj && typeof obj.ownerId === 'string' &&
    'ownerName' in obj && typeof obj.ownerName === 'string' &&
    'organizationId' in obj && typeof obj.organizationId === 'string' &&
    'accessType' in obj && Utils.isAccessType(obj.accessType) &&
    'paused' in obj && Utils.isStringArray(obj.paused) &&
    'unpaused' in obj && Utils.isStringArray(obj.unpaused) &&
    'robot' in obj && typeof obj.robot === 'string'
    // TODO not adding for now to allow backwards compatibility.
    // && 'wandbLink' in obj && typeof obj.wandbLink === 'string'
  );
}

export type AccessType = 'private' | 'organization' | 'public';
