import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../shared/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {DataService} from '../../shared/data.service';
import {RerouteService} from '../../shared/reroute.service';
import { Location } from '@angular/common';
import {Subscription} from 'rxjs';
import {UserProfile} from '../../model/user-profile';
import {ConfigurationService} from '../../shared/configuration.service';
import {CleaningService} from '../../shared/cleaning.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  pages: string[] = [
    'training',
  // 'run',
  'keypads',
  // 'robots',
  // 'brains',
  'account',
  // 'billing',
  'logout'];
  pagesCamelCase: string[] = [
    'Training',
  // 'Run',
  'Keypads',
  // 'Robots',
  // 'Brains',
  'Account',
  // 'Billing',
  'Logout'];

  userProfile: any;
  userName = '';

  selectedPage = '';
  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private rerouteService: RerouteService,
              private location: Location,
              private configurationService: ConfigurationService,
              // This service is never read, but the service needs to live so do not remove this
              private cleaningService: CleaningService) {}

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    const activePath = this.activatedRoute.snapshot.url.join('/');
    const origin = window.location.origin || `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
    let fullUrl = `${origin}${this.location.prepareExternalUrl(this.router.url)}`;

    const prefixes = ['localhost:4200', 'app.mauhn.com'];
    for (const prefix of prefixes) {
      if (fullUrl.indexOf(prefix) > -1) {
        fullUrl = fullUrl.substr(fullUrl.indexOf(prefix) + prefix.length);
      }
    }

    this.selectedPage = activePath;
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedOut') {
        this.rerouteService.linkAfterLogin = fullUrl;
        this.router.navigate(['/login']);
      } else if (authState === 'signedInAndNotVerified') {
        alert('Your account has not yet been verified. First verify your email by clicking the link that has been sent to you');
        this.rerouteService.linkAfterLogin = fullUrl;
        this.router.navigate(['/login']);
      } else if (authState === 'signedInAndVerified') {
        this.subscriptions.push(this.dataService.userProfileSnapshotChanges().subscribe(data => {
          const userProfile: UserProfile = data.payload.data() as UserProfile;
          this.userProfile = userProfile;
        }, err => {
          alert(err.message);
        }));
      }
    }));
    console.log(this.configurationService.getEnvironmentName());
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  logout(): void {
    this.authService.logout();
  }
}
