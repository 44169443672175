import {Component, OnDestroy, OnInit} from '@angular/core';
import {Checkpoint} from '../../../model/checkpoint';
import {AuthService} from '../../../shared/auth.service';
import {DataService} from '../../../shared/data.service';
import {Subscription} from 'rxjs';
import Utils from '../../../shared/utils';



@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, OnDestroy {
  checkpointsSortedAsHierarchy: Checkpoint[] = [];
  numTabsDisplayedPerCheckpoint: Map<string, number> = new Map<string, number>();

  subscriptions: Subscription[] = [];

  selectedCheckpointId: string | undefined;
  selectedCheckpointName: string | undefined;

  showOverlay = false;
  waitingForCallBack = false;

  confirmationName = '';
  isNameIncorrect = false;


  constructor(private authService: AuthService,
              private dataService: DataService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.authState.subscribe(authState => {
      if (authState === 'signedInAndVerified') {
        this.subscriptions.push(this.dataService.userProfileSnapshotChanges().subscribe(res => {
          this.dataService.getAllCheckpointsOrganization().subscribe(checkpoints => {
            checkpoints = this.sortCheckpointsMostRecentFirst(checkpoints);
            this.sortCheckpointsAsHierarchy(checkpoints);
          });
        }));
      }
    }));
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  }
  
  sortCheckpointsMostRecentFirst(checkpoints: Checkpoint[]): Checkpoint[] {
    return checkpoints.sort((a, b) => {
      return parseFloat(b.startTrainingSessionTime) - parseFloat(a.startTrainingSessionTime);
    });
  }

  sortCheckpointsAsHierarchy(checkpoints: Checkpoint[]) {
    // Reset
    this.checkpointsSortedAsHierarchy = [];
    this.numTabsDisplayedPerCheckpoint = new Map<string, number>();

    // Create hashmap containing all checkpoints connect to an empty list of children
    // A parent <> child relation refers to a checkpoint that continued training from its parent
    // Checkpoints without parent have root as their parent
    const childrenMap: Map<string, Checkpoint[]> = new Map<string, Checkpoint[]>();
    childrenMap.set('root', []);
    for (const checkpoint of checkpoints) {
      childrenMap.set(checkpoint.id, []);
    }

    // Fill childrenMap with children
    for (const checkpoint of checkpoints) {
      const parentId = checkpoint.startedFromCheckpointId ? checkpoint.startedFromCheckpointId : 'root';
      const children: Checkpoint[] = childrenMap.get(parentId) as Checkpoint[];
      children.push(checkpoint);
    }

    // Fill checkpointsSortedAsHierarchy and numTabsDisplayedPerCheckpoint by navigating the relation tree in a depth-first
    // search strategy starting with root
    this.sortCheckpointsAsHierarchyRecursivePart('root', childrenMap, 0);
  }

  sortCheckpointsAsHierarchyRecursivePart(checkpointId: string, childrenMap: Map<string, Checkpoint[]>, numTabs: number) {
    const children: Checkpoint[] = childrenMap.get(checkpointId) as Checkpoint[];
    for (const child of children) {
      this.checkpointsSortedAsHierarchy.push(child);
      this.numTabsDisplayedPerCheckpoint.set(child.id, numTabs);
      this.sortCheckpointsAsHierarchyRecursivePart(child.id, childrenMap, numTabs + 1);
    }
  }

  printTabs(checkpoint: Checkpoint) {
    let s = '';
    const numTabs = this.numTabsDisplayedPerCheckpoint.get(checkpoint.id) as number;
    for (let i = 0; i < numTabs; i++) {
      s += '|--';
    }
    return s;
  }

  printTime(time: string): string {
    return Utils.convertTimeStampToHumanReadableDateString(time);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  prepareDelete(checkpoint: Checkpoint): void {
    this.selectedCheckpointId = checkpoint.id;
    this.selectedCheckpointName = checkpoint.brainName;
    this.showOverlay = true;
    if (!this.selectedCheckpointName) {
      alert('undefined checkpointName');
      return;
    }
  }

  confirmDeletion() {
    this.isNameIncorrect = this.confirmationName !== this.selectedCheckpointName;
    if (!this.isNameIncorrect) {
      if (this.selectedCheckpointId) {
        this.waitingForCallBack = true;
        this.dataService.deleteCheckpoint(this.selectedCheckpointId).subscribe(() => {
            this.reset();
          },
          error => {
            this.reset();
            alert(error.message);
          });
      } else {
        alert('Checkpoint not specified');
      }
    }
  }

  reset() {
    this.showOverlay = false;
    this.waitingForCallBack = false;
    this.isNameIncorrect = false;
    this.confirmationName = '';
  }
}
