import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev.browser';
import {Keypad} from '../model/keypad';
import {Checkpoint} from '../model/checkpoint';
import {v4 as uuidv4} from 'uuid';
import {RobotService} from './robot.service';
import {BehaviorSubject} from 'rxjs';

// Conditionally import Electron IPC if in an Electron environment
declare const window: any;
let ipcRenderer: any;
if (environment.electron) {
  ipcRenderer = window.electron.ipcRenderer;
}

declare global {
  interface Window {
    electron: {
      ipcRenderer: {
        send(channel: string, ...args: any[]): void;
        on(channel: string, listener: (...args: any[]) => void): void;
      };
    };
  }
}


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  usePackagedPython = false;

  branch = 'prod';

  currentRunType: RunType = 'Start new brain';
  currentBrainName = '';
  currentBrainSize = 'medium';
  runActorInCloud = false;
  currentRobot = this.robotService.getRobots(this.isProduction(), this.runActorInCloud)[0];

  useSameRobotAsExistingCheckpoint = true;
  selectedExistingCheckpoint: Checkpoint | undefined;
  activeCheckpoint: Checkpoint | null = null;

  currentKeypad: Keypad | null = null;
  uuid = uuidv4();

  trainingState: BehaviorSubject<TrainingState> = new BehaviorSubject<TrainingState>(TrainingState.NO_TRAINING);

  constructor(private robotService: RobotService) {
    this.trainingState.subscribe(trainingState => {
      console.log(trainingState);
    });

    if (this.isElectron()) {
      window.electron.ipcRenderer.on('ipcRendererError', (event: any, eventMessage: any) => {
        if (typeof eventMessage === 'string' && (eventMessage.includes('The WebSocket transport is not available')
            || eventMessage.includes('WebSocket transport not available')
            || eventMessage.includes('POST /pause HTTP/1.1'))) {
          // do nothing
        } else {
          alert('IpcRenderer Error: ' + eventMessage);
        }
      });
      if (!this.isProduction()) {
        window.electron.ipcRenderer.on('ipcRendererMessage', (event: any, eventMessage: any) => {
          console.log(eventMessage);
        });
      }
    } else {
      this.runActorInCloud = true;
    }
  }

  resetState() {
    this.uuid = uuidv4();
  }

  isElectron() {
    return environment.electron;
  }

  isProduction() {
    return environment.production;
  }

  getIpcRenderer(): any {
    if (!this.isElectron()) {
      alert('Attempt to access ipcRenderer while this is not an Electron application');
    } else {
      return ipcRenderer;
    }
  }

  getEnvironmentName(): string {
    const env = environment;
    return env.name;
  }

  detectOperatingSystem(): OperatingSystem {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();

    if (platform.indexOf('win') !== -1) {
      return OperatingSystem.WINDOWS;
    } else if (platform.indexOf('mac') !== -1) {
      return OperatingSystem.MACOS;
    } else if (platform.indexOf('linux') !== -1 || userAgent.indexOf('x11') !== -1) {
      return OperatingSystem.LINUX;
    } else {
      return OperatingSystem.UNKNOWN;
    }
  }
}

export enum OperatingSystem {
  WINDOWS, MACOS, LINUX, UNKNOWN
}

export enum TrainingState {
  NO_TRAINING = 'NO_TRAINING',
  WAITING_FOR_CLOUD_SERVER = 'WAITING_FOR_CLOUD_SERVER',
  PAUSE = 'PAUSE',
  WAITING_FOR_UNPAUSE = 'WAITING_FOR_UNPAUSE',
  SESSION_ONGOING = 'SESSION_ONGOING'
}

export type RunType = 'Start new brain' | 'Use existing brain';
export type BrainSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
