import { Injectable } from '@angular/core';
import {Robot} from '../model/robot';

@Injectable({
  providedIn: 'root'
})
export class RobotService {
  private robots: Robot[] = [];
  constructor() {
    const mountainCar: Robot = {
      name: 'MountainCar',
      backendName: 'mountaincar_tamer',
      actionsContinuous: [],
      actionsDiscrete: ['Left', 'None', 'Right'],
      showInProduction: true,
      possibleToRunActorInCloud: false,
      imageData: 'mountaincar.png',
      description: 'A simple sweeping game that requires 15-20 minutes training to solve.',
      configs: {}
    };
    const cartpole: Robot = {
      name: 'CartPole',
      backendName: 'cartpole_tamer',
      actionsContinuous: [],
      actionsDiscrete: ['Left', 'Right'],
      showInProduction: true,
      possibleToRunActorInCloud: false,
      imageData: 'cartpole.png',
      description: 'A simple balance game that requires 15-20 minutes training to solve.',
      configs: {}
    };
    const robothorFindApple: Robot = {
      name: 'RoboThor find apple',
      backendName: 'robothor_apple_nav',
      actionsDiscrete: ['MoveAhead', 'RotateLeft', 'RotateRight', 'None'],
      actionsContinuous: [],
      showInProduction: true,
      possibleToRunActorInCloud: true,
      imageData: 'robothor_find_apple.png',
      description: 'This is an environment where you can choose what to learn. Teaching the robot to find the apple in the room takes ' +
        'about 60 minutes. For more information on this environment, visit: https://ai2thor.allenai.org/robothor/',
      configs: {}
    };
    const turtleBotContinuous: Robot = {
      actionsContinuous: ['motor_left', 'motor_right'],
      actionsDiscrete: [],
      backendName: 'tb3-burger',
      description: '',
      imageData: 'turtlebot.jpg',
      name: 'turtleBotContinuous',
      showInProduction: false,
      configs: {},
      possibleToRunActorInCloud: false
    };
    const frankieContinuous: Robot = {
      actionsContinuous: ['motor_left', 'motor_right'],
      actionsDiscrete: [],
      backendName: 'frankie',
      description: '',
      imageData: 'frankie.jpg',
      name: 'frankeContinuous',
      showInProduction: false,
      configs: {},
      possibleToRunActorInCloud: false
    };
    const fanucSimulation: Robot = {
      actionsContinuous: ['x', 'y', 'z', 'w', 'p', 'r', 'gripper'],
      actionsDiscrete: [],
      backendName: 'fanuc_pick_and_place_simulation',
      description: '',
      imageData: 'fanucSimulation.png',
      name: 'Fanuc Simulation',
      showInProduction: true,
      configs: {
        actor: '--env.fanuc.headless False',
        actorAndLearner: '--env.fanuc.headless True',
        learner: '--env.fanuc.headless False'
      },
      possibleToRunActorInCloud: true
    };
    const fanucRealLife: Robot = {
      actionsContinuous: ['x', 'y', 'z', 'w', 'p', 'r', 'gripper'],
      actionsDiscrete: [],
      backendName: 'fanuc_pick_and_place_real',
      description: '',
      imageData: 'fanucRealLife.jpg',
      name: 'Fanuc Real Life',
      showInProduction: true,
      configs: {
        actor: '--env.fanuc.headless False',
        actorAndLearner: '--env.fanuc.headless False',
        learner: '--env.fanuc.headless False'
      },
      possibleToRunActorInCloud: false
    };

    this.robots.push(mountainCar);
    this.robots.push(cartpole);
    // this.robots.push(robothorFindApple);
    this.robots.push(turtleBotContinuous);
    this.robots.push(frankieContinuous);
    this.robots.push(fanucSimulation);
    this.robots.push(fanucRealLife);
  }

  public getRobots(isProduction: boolean, runActorInCloud: boolean) {
    const robots: Robot[] = [];
    for (const robot of this.robots) {
      if ((robot.showInProduction || !isProduction)
        && (robot.possibleToRunActorInCloud || !runActorInCloud)) {
        robots.push(robot);
      }
    }
    return robots;
  }

  public getRobot(robotName: string, isProduction: boolean, runActorInCloud: boolean) {
    const robots: Robot[] = this.getRobots(isProduction, runActorInCloud);
    for (const robot of robots) {
      if (robot.name === robotName) {
        return robot;
      }
    }
    throw new Error('Did not find valid robot');
  }
}
