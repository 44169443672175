<div class="container-fluid">
  <div class="row mt-5">
    <div class="col"></div>
    <div class="col-4 min-width">
      <div class="card shadow">
        <div class="card-body">
          <form (keydown)="onKeyDown($event)">
            <form>
              <div class="mb-3">
                <h4 class="text-dark-muted">Login</h4>
              </div>
              <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="email">
              </div>
              <div class="mb-3">
                <label class="form-label">Password</label>
                <input type="password" class="form-control" name="password" [(ngModel)]="password">
              </div>
              <button data-cy="login-btn" #submitButton type="button" class="btn btn-info" (click)="login()">Login</button>
              <div class="row">
                <div class="col-6">
                  <a routerLink="/register" style="text-decoration: none;">Need Account? <span class="text-danger">Register</span> </a>
                </div>
                <div class="col-md-6">
                  <a routerLink="/forgot-password" class="text-dark" style="text-decoration: none;">Forgot Password ? </a>
                </div>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
