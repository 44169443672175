import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {PythonServerService} from './python-server.service';
import {ArgumentsService} from './arguments.service';
import {SocketService} from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class CleaningService {

  constructor(private configurationService: ConfigurationService,
              private pythonServerService: PythonServerService,
              private argumentService: ArgumentsService,
              private socketService: SocketService) {
    this.pythonServerService.ec2instanceId.subscribe(ec2instanceId => {
      if (!ec2instanceId || ec2instanceId === '') {
        this.doCleanup();
      }
    });
  }

  doCleanup() {
    console.log('in cleanup');
    this.configurationService.resetState();
    this.argumentService.updateArgumentString();
    this.pythonServerService.progress = 0;
    this.pythonServerService.errorFetchingLogsCounter = 0;
    this.pythonServerService.errorNoLogOutputReceivedCounter = 0;
    this.pythonServerService.errorScriptNotRunningCounter = 0;
    this.socketService.invalidMove.next(undefined);
  }
}
