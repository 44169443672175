import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RerouteService {
  linkAfterLogin = 'training';
  constructor(private router: Router) { }

  navigateToLinkAfterLogin() {
    console.log('in navigateToLinkAfterLogin');
    console.log(this.linkAfterLogin);

    // Case 1: Full URL
    if (this.linkAfterLogin.startsWith('http')) {
      const url = new URL(this.linkAfterLogin);
      let path = url.pathname;

      // Remove the 'TurboTeach/' segment if present
      const str = '/TurboTeach/';
      if (path.startsWith(str)) {
        path = path.substr(str.length);
      }

      if (url.search) {
        const queryParams: any = {};
        url.searchParams.forEach((value, key) => {
          queryParams[key] = value;
        });

        this.router.navigate([path], { queryParams });
      } else {
        this.router.navigate([path]);
      }
    }
    // Case 2: Relative Path or Case 3: Absolute Path
    else {
      const str = 'TurboTeach/';
      if (this.linkAfterLogin.includes(str)) {
        this.linkAfterLogin = this.linkAfterLogin.split(str).pop() || '';
      }
      this.router.navigate([this.linkAfterLogin]);
    }
  }
}
