<div style="display: flex;">
  <app-dashboard></app-dashboard>
  <div class="content">
    <form *ngIf="!isElectron">
      <div class="mb-3">
        <h4 class="text-dark-muted">Download Robot Software</h4>
      </div>
      <p>Download here the software to be installed on your robot.</p>
      <button type="button" class="btn btn-info" (click)="downloadRobotSoftware()">Download</button>
    </form>
  </div>
</div>
