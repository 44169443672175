import {Injectable} from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {Checkpoint} from '../model/checkpoint';
import {Robot} from '../model/robot';
import {RobotService} from './robot.service';

@Injectable({
  providedIn: 'root'
})
export class ArgumentsService {

  argumentString = '';
  argumentStringActor = 'First start Cloud server';
  argumentStringLearner = '';
  argumentStringActorAndLeaner = '';

  learnerPublicDnsName = '';

  argumentStringClientStatus: ArgumentStringClientStatus = 'unknown';

  constructor(private configurationService: ConfigurationService,
              private robotService: RobotService) {
  }

  updateArgumentString() {
    // Check if settings are okay given starting from existing brain
    if (this.configurationService.currentRunType === 'Use existing brain') {
      // Use the same brainSize as existing checkpoint
      const existingCheckpoint: Checkpoint | undefined = this.configurationService.selectedExistingCheckpoint;
      if (existingCheckpoint) {
        this.configurationService.currentBrainSize = existingCheckpoint.modelSize;
        if (this.configurationService.useSameRobotAsExistingCheckpoint) {
          this.configurationService.currentRobot = this.robotService.getRobot(existingCheckpoint.robot,
              this.configurationService.isProduction(), this.configurationService.runActorInCloud);
        }
      } else {
        console.log('No existing brain selected');
      }
    }

    // Update common arguments
    const uuid = this.configurationService.uuid;
    const argumentString = '--logdir logdir/' + uuid + ' --s3.path ' + uuid + ' --configs ' +
      this.configurationService.currentRobot.backendName + ' ' + this.configurationService.currentBrainSize;
    this.argumentString = argumentString;

    // Update argumentStringClientStatus
    if (this.configurationService.runActorInCloud) {
      this.argumentStringClientStatus = 'irrelevant, actor and learner run in the cloud';
    } else if (this.learnerPublicDnsName === '') {
      this.argumentStringClientStatus = 'first start cloud server';
    } else {
      this.argumentStringClientStatus = 'valid';
    }

    // Fetch configs specific for the chosen robot
    const robot: Robot = this.configurationService.currentRobot;
    const configs: any = robot.configs;

    // Update actor argumentString
    let configString = 'client_only';
    if (this.configurationService.isProduction() && this.configurationService.usePackagedPython) {
      configString += ' cpu';
    }
    this.argumentStringActor = this.insertConfigOption(argumentString, configString) + ' --mp.pyro_server_host ' +
      this.learnerPublicDnsName + ' --envs.user_reward_source flask';
    if (configs.actor) {
      this.argumentStringActor += ' ' + configs.actor;
    }

    // Update learner argumentString
    this.argumentStringLearner = this.insertConfigOption(argumentString, 'server_only');
    if (configs.learner) {
      this.argumentStringLearner += ' ' + configs.learner;
    }

    // Update actorAndLearner argumentString
    this.argumentStringActorAndLeaner = this.insertConfigOption(argumentString, 'old_gpu');
    if (configs.actorAndLearner) {
      this.argumentStringActorAndLeaner += ' ' + configs.actorAndLearner;
    }
  }

  insertConfigOption(argumentString: string, configOption: string): string {
    // Find the configs
    const configSeparator = ' --configs';
    const splitOnConfig = argumentString.split(configSeparator);
    const preConfig = splitOnConfig[0];
    const postConfig = splitOnConfig[1];

    // Separate the config options from the other flags
    const dashSeparator = ' --';
    const splitOnDashes = postConfig.split(dashSeparator);
    const configs = splitOnDashes[0];
    let otherFlags = splitOnDashes.slice(1).join(dashSeparator);
    if (otherFlags) {
      otherFlags = dashSeparator + otherFlags;
    }

    // Insert the new config option at the end of the configs
    return preConfig + configSeparator + configs + ' ' + configOption + otherFlags;
  }
}

export type ArgumentStringClientStatus = 'unknown' | 'valid' | 'first start cloud server' |
  'irrelevant, actor and learner run in the cloud';
